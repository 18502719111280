import React, { Component } from 'react';
import axios from 'axios';
import Card1 from './card';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'jalali-moment';


class Scroll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            articles : [],
            nextPage : 1,
            hasMore : true
        }
    }

    handleLoadMore() {
        const requestOptions = {
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
        };
        axios.post(`https://api.quiz360.ir/public/api/v1/publicpage?page=${this.state.nextPage}`,requestOptions)
            .then(response => {
                //console.log(response.data.data)
                const {data} = response.data;
                const {current_page , last_page} = response.data.meta
                
                //console.log(data);
                //console.log(current_page,last_page)
                this.setState(prevState => ({
                    articles: [ ...prevState.articles , ...data],
                    hasMore: current_page !== last_page,
                    nextPage : current_page + 1
                }))
            })
            .catch(error => {
                //console.log(error)
            })
    }

    render() {
        return (
            <div style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                
                <InfiniteScroll
                    className="row rtl"
                    pageStart={0}
                    loadMore={this.handleLoadMore.bind(this)}
                    hasMore={this.state.hasMore}
                    loader={<div><br></br><div class="spinner-border"  role="status">
                    <span class="sr-only">Loading...</span>
                  </div></div>}
                >
                    {this.state.articles.map((item,index)=>{return <Card1  
                    title={item.qname}
                    id1={item.use+" استفاده"} 
                    id2={item.examnumber}
                    id2copy={item.examnumber}
                    id3={moment(item.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}
                    id4={true ? (<div class="btn btn-info">شروع آزمون</div>):('')}
                    id4link={'/qnswerinfo/'+item.examnumber}
                    id5={true ? (<div class="btn-outline-danger">گزارش</div>):('')}
                    id5link={'/reportquiz/'+item.examnumber}

                    key={index} />;})}
                </InfiniteScroll>


            </div>
        );
    }
}

export default Scroll;