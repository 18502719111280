import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import  { Redirect } from 'react-router-dom';

function Answerresultwithansn(){
    const { register, handleSubmit, errors } = useForm();
    const[dataset,setdataset] = useState(false);
    const [errecv,seterrecv] = useState(false);
    const [sendda,setsendda] = useState();


    const onSubmit = data =>{
        //console.log(data)
        setsendda(data)
        setdataset(true);
    }


    return(
        <div>
            <h2><span style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}} class="badge badge-info"> نتیجه آزمون</span></h2>
            <div style={{'text-align': 'center'}} >
    <form class="form-signin" onSubmit={handleSubmit(onSubmit)}>
        <div class="form-row">
        <div class="form-group col-md-12">
            <label for="inputexamnumber4">شماره آزمون</label>
        <div >
            <input type="text" class="form-control" placeholder="شماره آزمون" name="answernumber" ref={register({required: {value:true,message: "این فیلد الزامی است"}})} />
            <div class="alert-danger">
                {errors?.answernumber?.message}
            </div>
      </div>
      </div>
      </div>
      <div class="form-row">
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
            <input type="submit" class="btn btn-outline-secondary" value="تایید" />
        </div>
    </div>
    
            {dataset ? (<Redirect to={{pathname: '/Answerresultshow/'+sendda.answernumber}}/>) : (<div>{errecv ? (<div class="alert alert-warning" role="alert">خطایی رخ داده است</div>) : ('')}</div>)}
    </form>
    </div> 
        </div>
    );
}

export default Answerresultwithansn;