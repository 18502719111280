import React,{useState} from 'react';
import {useParams,Link} from "react-router-dom";
import { useForm } from 'react-hook-form';
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Reportquiz(){

    let { id } = useParams();
    const { register, handleSubmit, errors } = useForm();
    const[done,setdone] = useState(false);
    const[button,setbutton] = useState(false);
    const[retry,setretry] = useState(false);
    const[externalnumber,setexternalnumber] = useState()

    const onSubmit =(data)=>{
        //console.log(data);
        setbutton(true);

        const api_token = localStorage.getItem('api_token');

        const senddata = {'api_token':api_token,'message':data.message,'needdelete':data.needdelete,'examnumber':id}

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(senddata)
        };
        //console.log(requestOptions);
        fetch('https://api.quiz360.ir/public/api/v1/makereport', requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );
            ////console.log(resp?.data.api_token)
      
            const setdataapi = data1 => {

                
          if(data1?.data?.externalnumber){
            
            setexternalnumber(data1?.data?.externalnumber)
            setdone(true);
            
          }
          else if(data1?.data?.data == 0){
            setretry(true);
          }
          
      
          
        
        }
    }

    return(
        <div>
            {!done ? (
            <div className="text-center">
                
          <h2><span style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}} class="badge badge-danger">گزارش</span></h2>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div class="form-group col-md-12">
        <label for="exampleInputEmail1">پیام</label>
      <textarea name="message" placeholder="متن" class="form-control" rows="4" ref={register({required: {value:true,message:"این فیلد الزامی است"}, maxLength: {value:1000,message:"تعداد کارکتر ها بیش از حد مجاز است"}})} />
      <div class="alert-danger">
                {errors?.message?.message}
      </div>
      </div>
      
  <div class="form-group col-md-12">
  <input type="checkbox" class="form-check-input" placeholder="این سوال باید حذف شود"  name="needdelete" ref={register}/>
        <label class="form-check-label" for="gridCheck">
        این سوال باید حذف شود
      </label>
  
  </div>
      {!button ? (<input type="submit" class="btn btn-outline-danger" value="گزارش" />):(
      <button class="btn btn-outline-danger" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="sr-only">در حال گزارش ...</span>
      </button>)}
    </form>
    <div class="alert-warning" style={{textAlign:"center"}} role="alert">
    {retry ? (<div class="alert alert-danger">
            شما قبلا گزارش خود را ثبت کرده اید
            <br/>
            <Link to='/publicpage' className="btn btn-outline-secondary" >بازگشت</Link>
    </div>):('')}
    
    </div>
            </div>
            ):(
            <div className="text-center">
                <CopyToClipboard text={externalnumber}>
                    <input type="button" class="btn btn-outline-info" value="کپی" ></input>
                </CopyToClipboard>
                <div class="alert alert-success" role="alert">
                    گزارش شما با موفقیت ارسال شد
                    <br/>
                    شماره پیگیری گزارش: {externalnumber}
                </div>
                

                <Link to='/publicpage' className="btn btn-outline-secondary" >بازگشت</Link>
            </div>
            )}
        </div>
    );
}

export default Reportquiz;