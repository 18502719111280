import React,{useEffect,useState} from 'react'
import {useParams} from "react-router-dom";
import {Link} from 'react-router-dom';

function Veryfiyemail(){

    let { id } = useParams();
    const [answernumberprams,setanswernumberprams] = useState(id);
    const [load,setload] = useState(false);
    const[done,setdone] = useState(false);



    useEffect(() => {

        
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify({mailtoken:id})
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/veriyfiymail', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            setload(true);
            //console.log(data1.data.data[0])
        if(data1?.data?.data[0]==="1"){
          
            setdone(true)
          //console.log('getstatus')
        }
    
        
      
      }
        
      }, []);

    return(
        <div>
            {!load ? (<div class="text-center">
                
<div class="spinner-grow text-success" style={{'width': '6rem','height': '6rem'}} role="status">
  <span class="sr-only">در حال بارگیری</span>
</div>
            </div>):(<div>

                {!done ? (<div class="text-center">
                <div class="alert alert-warning" role="alert">
                    خطایی رخ داده لطفا بعدا تلاش کنید
                </div>
                <Link class="btn btn-outline-warning" to="/makenewveryfiy" >ارسال مجدد لینک فعالسازی</Link>
                </div>):(
                <div class="text-center">
                    <div class="alert alert-success" role="alert">
                    ایمیل شما با موفقیت فعال شد
                    </div>
                    <Link class="btn btn-outline-success" to="/login" >ورود</Link>
                </div>)}

            </div>)}

        </div>
    );
}

export default Veryfiyemail;