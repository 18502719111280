import React from 'react';
import {Route,Switch} from 'react-router-dom';
import PrivateRoute from '../obj/login/PrivateRoute';


import P404 from '../pages/p404';
import First from '../pages/first';
import Sign_up from '../pages/sign_up';
import Login from '../pages/login';
import ProfilePage from '../pages/ProfilePage'
import Login1 from '../pages/logintest';
import LogoutPage from '../pages/LogoutPage';
import App_q from '../pages/qmakeprofile';
import Qwrite from '../pages/qwrite';
import Test from '../pages/test';
import Answer_info from '../pages/answer';
import Quiz_info from '../pages/quiz';
import Viewanserresult from '../pages/viewanserresult';
import Endhalfquiz from '../pages/endhalfquiz';
import Viewqustion from '../pages/Viewqustion';
import Viewanswerinfo from '../pages/viewanswerinfo';
import Viewanswerinfowithexn from '../pages/viewanswerinfowithexn';
import Contactus from '../pages/contactus';
import Veryfiyemail from '../pages/veryfiyemail';
import Makenewveryfiy from '../pages/makenewveryfiy';
import Resetpasswordreq from '../pages/resetpasswordreq';
import Resetpassword from '../pages/resetpassword';
import Edituserinfo from '../pages/edituserinfo';
import Publicqustion from '../pages/publicqustion';
import Privatequstion from '../pages/privatequstion';
import Editqustion from '../pages/editqustion';
import Publicpage from '../pages/publicpage';
import Reportquiz from '../pages/reportquiz';
import Answerresultwithansn from '../pages/answerresult';
import Answerresultshow from '../pages/answerresultshow';
import Textpolicy from '../obj/policy/textpolicy';



function Route1(){
//<Route path="" component={} />
//<Route path="/test" component={Test} />
//<Route path="/login1" component={Login1} />
    return(
        <div>
            <Switch>
                <Route exact path="/" component={First} />
                <Route path="/sign_up" component={Sign_up} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={LogoutPage} />
                
                
                <Route path="/contactus" component={Contactus} />
                <Route path="/variyfiymail/:id" component={Veryfiyemail} />
                <Route path="/makenewveryfiy" component={Makenewveryfiy} />
                <Route path="/resetpasswordreq" component={Resetpasswordreq} />
                <Route path="/resetpassword/:id" component={Resetpassword} />
                <Route path="/publicpage" component={Publicpage} />
                <Route path="/textpolicy" component={Textpolicy} />
                
                
                
                <PrivateRoute path="/Answerresultshow/:id" component={Answerresultshow} />
                <PrivateRoute path="/answerresultwithansn" component={Answerresultwithansn} />
                <PrivateRoute path="/reportquiz/:id" component={Reportquiz} />
                <PrivateRoute path="/editqustion/:id" component={Editqustion} />
                <PrivateRoute path="/privatequstion/:id" component={Privatequstion} />
                <PrivateRoute path="/publicqustion/:id" component={Publicqustion} />
                <PrivateRoute path="/edituserinfo" component={Edituserinfo} />
                <PrivateRoute path="/viewanswerinfowithexn/:id?" component={Viewanswerinfowithexn} />
                <PrivateRoute path="/viewanswerinfo/:id" component={Viewanswerinfo} />
                <PrivateRoute path="/viewqustion" component={Viewqustion} />
                <PrivateRoute path="/endhalfquiz/:id?" component={Endhalfquiz} />
                <PrivateRoute path="/viewanserresult" component={Viewanserresult} />
                <PrivateRoute path="/qmakeprofile" component={App_q} />
                <PrivateRoute path="/qwrite" component={Qwrite} />
                <PrivateRoute path="/qnswerinfo/:id?" component={Answer_info} />
                <PrivateRoute path="/quizinfo/:id?" component={Quiz_info} />
                <PrivateRoute path="/profile" component={ProfilePage} />


                <Route path="*" component={P404} />
            </Switch>
        </div>
    );
}

export default Route1;