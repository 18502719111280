import React from 'react';
import {ListGroup} from 'react-bootstrap';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';

function List(props){

//<ListGroup.Item>{props.id4}</ListGroup.Item>

    return(
        <div >
       
            <div class="col-6">
           
        <Card style={{ width: '18rem' }}>
    <Card.Header>{props.title}</Card.Header>
  <ListGroup variant="flush">
  <CopyToClipboard text={props.id1copy}>
    <ListGroup.Item>{props.id1}</ListGroup.Item>
    </CopyToClipboard>
    <ListGroup.Item>{props.id2}</ListGroup.Item>
    <ListGroup.Item>{props.id3}</ListGroup.Item>
    <ListGroup.Item><Link to={props.id4link}>{props.id4}</Link></ListGroup.Item>
    <ListGroup.Item><Link to={props.id6link}>{props.id6}</Link></ListGroup.Item>
    <ListGroup.Item><Link to={props.id5link}>{props.id5}</Link></ListGroup.Item>
  </ListGroup>
</Card>


        
  
  </div>

</div>
  
    )
}

List.defaultProps = {

title:"Sample Title",
id1:"مقداری وجود ندارد",
id2:"مقداری وجود ندارد",
id3:"مقداری وجود ندارد",
id4:"مقداری وجود ندارد",
id5:"مقداری وجود ندارد",
id12:"مقداری وجود ندارد",
src:"https://homepages.cae.wisc.edu/~ece533/images/boat.png",
text:"Sample Text",
click:"1",
func:"بیشتر"
}

export default List;