import React from 'react';
import {Link} from 'react-router-dom';
import Hrader from '../obj/first/header';



function NavLink(){

    return(

      <div>
        <Hrader />
      <div class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">
  <nav class="my-2 my-md-1 mr-md-3">
  



  </nav>
</div>

</div>
    );
}

export default NavLink;




