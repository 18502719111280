import React from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';

function Q1write(){
    const id = localStorage.getItem('examnumber')
    const nm = localStorage.getItem('qname');



    return(
        <div style={{'text-align': 'center'}}>
            <Link class="btn btn-outline-success" to={"/publicqustion/"+id} >عمومی سازی</Link>
            <div class="alert alert-success" role="alert">
            <h3>نام آزمون : {nm} </h3>
            <CopyToClipboard text={parseInt(id)}>
                  <button class="btn btn-secondary">کپی</button>
            </CopyToClipboard>
            <div class="alert alert-dark" role="alert">
            <div> شماره آزمون : {id} </div>
            <div> افراد مختلف با داشتن این شماره می توانند در آزمون {nm} شرکت کنند </div>
            </div>
            </div>
        </div>
    );
}

export default Q1write;