import React from 'react';
import Body from '../obj/first/body';

import Carouselslide from '../obj/first/Carousel-slide';

function First() {
  return (
    <div>

      <div style={{'width': '100%','height':'500'}} className='slide'>
      <Carouselslide />
      </div>
      <Body />
    </div>
  );
}

export default First;
