import React from "react"
import useAuthActions from "../hook/useAuthActions";
import useHistory from "../hook/useHistory";

function LogoutPage() {
  const setLogin = useAuthActions()
  const history = useHistory()

  React.useEffect(() => {
    setLogin(false)
    localStorage.setItem('api_token','');
    localStorage.setItem('email','');
    localStorage.setItem('qname','');
    localStorage.setItem('examnumber','')
    localStorage.clear()  
    history.push("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <h1>لطفا کمی صبر کنید</h1>
}

export default LogoutPage
