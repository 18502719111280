import React from 'react';
import {Link} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowAltCircleUp} from '@fortawesome/free-solid-svg-icons';


function Footer() {
  return (
    <div className="text-center">  
    <footer class="container">
    <p class="float-right"><a href="#"><FontAwesomeIcon icon={faArrowAltCircleUp} /></a></p>
    <small><p> تمام حقوق مادی و معنوی این سایت برای تیم سازنده می باشد&copy; 2020-2021 &middot; <Link to='/textpolicy' type="button" class="btn btn-link"><small>قوانین</small></Link></p></small>
    
  </footer>
  </div>
  );

}

export default Footer;
