import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import  { Redirect } from 'react-router-dom'
import useHistory from "../hook/useHistory";
import {useParams} from "react-router-dom";

export default function Endhalfquiz() {
  const { register, handleSubmit, errors } = useForm();
  const [resp,setresp] = useState();
  const history = useHistory();
  const [sendda,setsendda] = useState();

  const [dataset,setdataset] = useState(false);
  const [errecv,seterrecv] = useState(false);

  let { id } = useParams();
  const [answernumberprams,setanswernumberprams] = useState(id);



  const onSubmit = data =>{
    setsendda(data)
    const email = localStorage.getItem('email');
    const api_token = localStorage.getItem('api_token');
    //********************************************* */

    const datasend2 = {'email':email,'api_token':api_token,'answernumber':data.answernumber};
        
        const requestOptions2 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify(datasend2)
      };

      //console.log(requestOptions2);
      
      fetch('https://api.quiz360.ir/public/api/v1/recivequstionhalfexam', requestOptions2)
          .then(response => response.json())
          .then(data1 =>setdataapi2(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi2 = data1 => {  
        if(data1?.data?.qjson){
          
          //setresp(data1.data.qjson)
          //console.log(data1)
          //setresp({'qjson':data1.data.qjson})
          ////console.log(data1.data.qjson)
          ////console.log(JSON.parse(data1.data.qjson))
          
          localStorage.setItem('qjson', data1.data.qjson)
          localStorage.setItem('examfinish',"0")
          setdataset(true)
          
          
          ////console.log(setresp)
          //localStorage.setItem('answernumber', data1.data.answernumber);
          //history.push("/quizinfo/"+data.examnumber)
          //localStorage.setItem('email', data1.data.email);
          //return <Redirect to={{pathname: '/quizinfo/'+data.examnumber}}/>;
        }
        else{
          
            seterrecv(true);
        }
        
      
      }

    //********************************************* */
    
    localStorage.setItem('answernumber',data.answernumber);
}
  //console.log(errors);
  
  return (
     <div style={{'text-align': 'center'}} >
    <form class="form-signin" onSubmit={handleSubmit(onSubmit)}>
        <div class="form-row">
        <div class="form-group col-md-12">
            <label for="inputexamnumber4">شماره آزمون</label>
        <div >
            <input type="text" defaultValue={answernumberprams} class="form-control" placeholder="شماره آزمون" name="answernumber" ref={register({required: {value:true,message: "این فیلد الزامی است"}})} />
            <div class="alert-danger">
                {errors?.answernumber?.message}
            </div>
      </div>
      </div>
      </div>
      <div class="form-row">
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
            <input type="submit" class="btn btn-outline-secondary" value="تایید" />
        </div>
    </div>
    
            {dataset ? (<Redirect to={{pathname: '/quizinfo/'+sendda.answernumber}}/>) : (<div>{errecv ? (<div class="alert alert-warning" role="alert">خطایی رخ داده است</div>) : ('')}</div>)}
    </form>
    </div> 
  );
}