import React,{useEffect,useState} from 'react';
import {useParams} from "react-router-dom";
import  {Link} from 'react-router-dom';
import useHistory from "../hook/useHistory";

function Publicqustion(){

    const[loading,setloading] = useState(false);
    const[done,setdone] = useState(false);
    const history = useHistory()
    let { id } = useParams();


    useEffect(() => {

        
        const apitoken = localStorage.getItem('api_token');
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify({'api_token':apitoken,'examnumber':id})
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/makepublic', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            
            //console.log(data1)
        if(data1?.data?.data[0]===1){
            
            setloading(true);
            history.push("/viewqustion")
            //console.log('getstatus')
        }else{
            setloading(true);
            setdone(true)
        }
    
        
      
      }
        
      }, []);


    return(
        <div>
            {!loading ? (
            <div>
                <div class="spinner-grow text-success" style={{'width': '6rem','height': '6rem'}} role="status">
                    <span class="sr-only">در حال بارگیری</span>
                </div>
            </div>
            ):(
            <div>
                {!done ? (
                <div class="alert alert-success" role="alert">
                     عملیات با موفقیت انجام شد
                </div>
):(
                <div class="text-center">
                   <div class="alert alert-warning" role="alert">
                        عمومی سازی سوال دچار خطا شد
                    </div>
                    <Link class="btn btn-outline-warning" to="/viewqustion" >بازگشت</Link>
                </div>
                )}
            </div>
            )}
        </div>
    );
}

export default Publicqustion;