import React from 'react';


function Textpolicy(){

    return(
        <div className="text-center">
            <div class="alert alert-info" role="alert">
            به سایت کویز 360 خوش آمدید
            <br/>
این سایت با هزینه شخصی تیم سازنده جهت ارتقای آموزش مجازی ساخته شده است.
<br/>
این سایت امکانات متنوعی را برای شما فراهم می کند که شما می توانید از تمامی امکانات به در راه  درست استفاده کنید.
<br/>
تیم سازنده این حق را برای خود محفوظ نگاه میدارد که هر کدام از شرایط و ضوابط را از طریق به روزرسانی این صفحه بنا به صلاحدید خود مورد بازبینی و تغییر قرار دهد. زمانی که تیم ما شرایط و ضوابط را بروزرسانی میکند، از تلاشهای معقولی برای اطلاع رسانی به شما در مورد به بروزرسانیهای این شرایط استفاده خواهد کرد اما این امر وظیفه شماست که شرایط و ضوابط را چک کنید تا از تغییرات آن باخبر شوید. لازم به ذکر است که ادامه دسترسی شما به وبسایت به معنای پذیرش و موافقت با این تغییرات است. هر گونه تغییر در شرایط و ضوابط از تاریخ انتشار تریب اثر داده میشود.
لازم است قبل از دسترسی و استفاده از خدمات وبسایت حتما شرایط و ضوابط پیش رو را با دقت مطالعه نمایید. سوالات خود را در مورد این شرایط و ضوابط از طریق صفحه تماس با ما  در میان بگذارید.
منظور از ما در ادامه تیم سازنده هست.
<br/>
پذیرش شرایط 
<br/>
شما با ادامه استفاده از وبسایت و خدمات آن نشان میدهید که شرایط و ضوابط را پذیرفتهاید، همچنین شما با کلیک کردن بر روی کادر قبول یا پذیرش شرایط و ضوابط که توسط ما در این بخش قرار داده میشود موافقت خود را اعلام مینمایید.
<br/>
نخستین گام برای دسترسی به وبسایت این است که به عنوان کاربر در سایت ثبت نام کنید. در بخشی از فرآیند ثبت نام در سایت ممکن است از شما خواسته شود که برخی از اطلاعات شخصی خود را وارد کنید نظیر: 
<br/>
-	نام و نام خانوادگی
<br/>
-	آدرس ایمیل 
<br/>
-	شماره تلفن 
<br/>
-	آدرس 
<br/>
*شما متعهد میشوید که هر اطلاعاتی را که در راستای تکمیل کردن فرآیند ثبت نام به ما میدهید، دقیق، جدید و صحیح است. 
<br/>
* زمانی که فرآیند ثبت نام تکمیل شود، شما عضو وبسایت محسوب میشوید و محدودیتهای این شرایط و ضوابط را میپذیرید.بعد از فرآیند احراز هویت شما به عنوان یک عضو دسترسی سریع به خدمات سایت به شما اعطا میشود. 
شما در شرایط زیر مجاز به استفاده از وبسایت و پذیرش ضوابط نیستید:
<br/>
*شما شخصی باشید که مطابق قوانین این کشور یا کشورهای دیگر از جمله کشورهایی که در آن ساکن هستید یا از خدمات وبسایت در آن استفاده میکنید، از دریافت خدمات منع شده باشید.
<br/>
تعهدات کاربران
<br/>
*استفاده شما از وبسایت مطابق شرایط و ضوابط تعیین شده در وبسایت و یا هر قانون، مقررات و یا قوانین قابل قبول یا دستورالعملهای قانونی در حوزههای مربوطه ممنوع نشده باشد.
<br/>
*شما مسئولیت فردی برای حفاظت از محرمانگی رمز و یا آدرس ایمیل خود را دارید. استفاده از گذرواژه شما توسط هر شخصی دیگر ممکن است منجر به لغو سریع دسترسی شما شود.
<br/>
*هر گونه استفاده از اطلاعات ثبت نامی به وسیله فرد دیگر یا اشخاص ثالث به شدت ممنوع است. شما میپذیرید که بلافاصله ما  را از هرگونه استفاده غیرمجاز از رمز عبور یا آدرس ایمیل خود یا هر گونه نقص امنیتی که از آن آگاه شدهاید مطلع سازید.
<br/>
*دسترسی و استفاده از این وبسایت محدود، غیرقابل انتقال و صرفا مجاز برای استفاده از آن توسط شما براساس اهدافی است که ارائه خدمات را توسط ما  فراهم می کند.
<br/>
*شما میپذیرید که تبلیغات تجاری، لینکهای وابسته و سایر انواع درخواست ها بدون اطلاع رسانی قبلی از سایت حذف میشود و این امر ممکن است منجر به قطع خدمات خرید شما هم شود. در صورت بروز استفاده غیر قانونی و غیر مجاز از وبسایت اقدامات قانونی مناسب توسط ما یا نماینده قانونی صورت خواهد گرفت.
<br/>
*شما متعهد میشوید که بدون اجازه کتبی از ما اقدام به کپی کردن محصولات و خدمات وبسایت نمینمایید.
<br/>
*شما میتعهد میشوید که وظیفه بروزرسانی کردن اطلاعات در سایت را به عهده بگیرید و ما  هیچ مسئولیتی در قبال این موضوع نخواهیم داشت. 
<br/>
استفاده از وبسایت و مطاب آن در موارد زیر غیر مجاز و ممنوع است:
<br/>
*برای اهداف غیر قانونی 
<br/>
*برای مجبور کردن دیگران به جهت مشارکت در امور غیر قانونی 
<br/>
*برای نقض مقررات داخلی و بین المللی 
<br/>
*برای نقض حقوق مالکیت فکری وبسایت و حقوق مالکیت فکری دیگران 
<br/>
*برای تبعیض، سواستفاده، آسیب وارد کردن و توهین براساس جنسیت، گرایش جنسی، مذهب، قومیت، نژاد، سن، منشا ملی یا ناتوانی جسمی
<br/>
*برای ارسال اطلاعات نادرست یا گمراه کننده 
<br/>
*برای آپلود یا انتقال ویروسها و یا هر نوع دیگر از کدهای مخرب که بر عملکرد خدمات این وب سایت، وبسایتهای دیگر و یا اینترنت تاثیر میگذارد.
<br/>
*برای جمعآوری یا ردیابی اطلاعات شخصی دیگران و استفاده غیر مجاز از آن
<br/>
*برای هر هدف ناخوشایند یا غیر اخلاقی
<br/>
*برای دخالت در سرویسهای امنیتی
این حق برای ما محفوظ است که به دسترسی و استفاده شخص برای این قبیل موارد پایان دهیم.
خرید محصولات و ضوابط بازگشت:
<br/>
*برای استفاده از خدمات و یا کالاهای وبسایت باید هزینه خرید تعیین شده در وبسایت برای محصول مورد نظر را بپردازید.
<br/>
*پرداخت هزینه خرید ممکن است از طریق یکی از ارائه دهندگان درگاههای پرداخت تایید شده در وبسایت صورت بگیرد.
<br/>
*شما تضمین می کنید که برای استفاده از خدمات وبسایت با این خدمات به طور کامل آشنایی دارید و محدودیتهای ناشی از شرایط و ضوابط مورد استفاده، سیاست حریم خصوصی و سایر مستندات قانونی دیگر را که به وسیله درگاههای پرداخت ایجاد میشوند، میپذیرید.
<br/>
* بعد از پرداخت، قیمت خرید به وسیله تیم ما تایید می شود. شما تاییدیهای دریافت خواهید کرد که نشان دهنده این است که پرداخت شما توسط ما دریافت شده است و ممکن است این ما جزئیات خرید را برای استفاده در آینده ضبط کنیم.
<br/>
*تیم ما  ممکن است بنا به صلاحدید خود بازپرداختی را برای بازگرداندن محصولات در بازه زمانی  30 روز در صورتی که بسته بندی محصول باز نشده باشد، فراهم کند. شما میپذیرید که مسئولیت هر گونه هزینه حمل و نقل مربوط به هر گونه بازپرداخت به عهده کاربر مورد نظر است.
ضمانتنامه:
<br/>
*محصولات ما  با تضمینهایی که مطابق قوانین حمایت از مصرف کننده این کشور است، ارائه میشود. شما حق دارید که جایگزین یا بازپرداختی برای خرابی عمده محصول داشته باشید. شما همچنین حق دارید که اگر محصولات از کیفیت قابل قبول برخوردار نبود، از ما بخواهید که جایگزین یا تعمیر کنیم.
<br/>
*شما میتوانید تحت این ماده ادعای ضمانت را در مورد محصولاتی که مشکل دارند، از تاریخ خرید شروع دوره ضمانت مطرح کنید.
<br/>
*برای بهره مندی از خدمات ادعای ضمانت در طول دوره ضمانت، شما بایستی مدارک خرید خود را که تاریخ خرید محصولات را نشان میدهد، توضیحات مربوط به محصولات و قیمت پرداخت شده برای محصولات را با اعلان کتبی به آدرس تیم ما و یا از طریق ایمیل به ما یا راه های فراهم شده دیگر ارسال نمایید.
<br/>
*جایی که ادعای تضمین محصولات پذیرفته شده است، تیم ما با صلاحدید خود، همه محصولات یا بخشی از محصولات معیوب خود را با معیار جدید در طول دوره ضمانت تعمیر یا جایگزین میکند و به هیچ وجه برای قطعات یا نیروی کار از شما هزینهای دریافت نمیکند. شما میپذیرید که به تنهایی مسئول هزینههای حمل و نقلی هستید که در راستای تسهیلات ادعای ضمانت ایجاد میشود.
<br/>
*این ضمانت تنها تضمین ارائه شده توسط ما  میباشد و باید تنها برای شما در کنار سایر حقوق دیگر و ذیل قانون مربوط به محصولاتی که این ضمانتنامه به آنها مربوط است، اجرا شود.
<br/>
*تمام ضمانتنامههای ضمنی شامل ضمانتهای تجاری و مناسب برای استفاده، محدود به دوره ضمانت میباشد.
ضمانتنامه هیچگاه نسبت به موارد زیر اعمال نمیشود:
<br/>
*ظاهر محصولات عرضه شده.
<br/>
*خطا در هنگام استفاده از محصول
تحویل:
<br/>
*در ارائه خدمات، تیم ما ممکن است گزینههای مختلف تحویل و بیمه ارائه شده توسط ارائه دهندگان خدمات تحویل را پیشنهاد دهد. شما میپذیرید که ما  ارائه دهنده گزینههای تحویل و بیمه نیست و صرفا تسهیل کننده تعامل شما با ارائه دهندگان خدمات تحویل میباشیم و لذا در قبال هیچ گونه خسارت یا آسیب، از دست دادن سود یا فرصت یا خسارت به حسن نیت که ناشی از یا در ارتباط با خدمات تحویل است، در برابر کاربران مسئولیتی ندارد.
در مواقعی که محصولی در طول خدمات تحویل گم میشود یا آسیب میبیند، شما باید:
<br/>
*به طور مستقیم با ارائه دهنده خدمات تحویل تماس بگیرید تا درخواست بازپرداخت و یا درخواست استفاده از بیمه را در دسترس داشته باشید.
<br/>
*از طریق ایمیل با تیم ما نیز در ارتباط باشید تا مشخص شود که چگونه محصولات در حمل و نقل آسیب دیده است، بنابراین از این طریق ما قادر هستیم که در این مورد تصمیم بگیریم که آیا ارائه دهنده خدمات تحویل به جهت چنین نقصی باید از لیست ارائه خدمات ما حذف شود یا خیر.
کپی رایت و مالکیت معنوی:
<br/>
*وبسایت، خدمات و تمامی محصولات مرتبط با تیم ما موضوع کپی رایت هستند. مطالب و موضوعات وبسایت از جمله نوشته ها، عکس ها ویدیوها لوگوها و ویس ها ) ذیل قانون این کشور و معاهدات بین المللی تحت حمایت کپی رایت قرار میگیرند. 
<br/>
* تیم ما  تمامی حقوق و منافع مرتبط با وبسایت را حفظ میکند: 
<br/>
*نام کسب و کار، نام تجاری، نام و آدرس سایت، علامت تجاری، طرح صنعتی، ثبت اختراع و کپی رایت این ما 
<br/>
*حق استفاده از نام کسب و کار، نام تجاری، طرح صنعتی، علامت تجاری و ...
<br/>
* شما بدون اجازه کتبی تیم ما و اجازه هر صاحب حق دیگری مجاز به انجام موارد زیر نیستید: پخش، انتشار مجدد، آپلود کردن، انتقال، ارسال، توزیع، نمایش یا پخش در عموم.
<br/>
* این ممنوعیت به مواد موجود در وبسایت که آزادانه برای استفاده مجدد در دسترس هستند یا در حوزه عمومی هستند، گسترش نمییابد.
<br/>
حریم خصوصی :
<br/>
*ما حریم خصوصی شما را موضوعی جدی تلقی میکنیم و هر گونه اطلاعاتی که از طریق استفاده شما از برنامه و یا خدمات ارائه شده است، به سیاست حفظ حریم خصوصی تیم ما مربوط میشود که در برنامه موجود است.
<br/>
سلب مسئولیت عمومی:
<br/>
*تیم ما هیچ گونه ضوابط، ضمانت، نمایندگیها یا شرایطی را در رابطه با سایر محصولات به غیر از موارد مطابق با این شرایط و ضوابط ایجاد نمیکند. 
<br/>
*تیم ما هر گونه تلاش برای اطمینان از اینکه محصول دقیقا در وبسایت نشان داده میشود، به کار میگیرد هر چند که اندازه، رنگ و بسته بندی ممکن است با آنچه که در وبسایت نمایش داده شده، متفاوت باشد. 
<br/>
*تیم ما تمام تلاش خود را به کار میگیرد که تا جایی که مقدور است تصویر و رنگ محصولات را دقیق نشان دهد ولی نمیتواند دقیق بودن صفحه نمایش سیستم شما را تضمین کند و در صورت مغایرت مسئولیتی به عهده ما نیست.
همچنین ما تضمین نمیکنیم که کیفیت محصولات، خدمات و اطلاعات تیم ما دقیقا مطابق انتطارات شما باشد.
<br/>
*هیچ چیز در این شرایط و ضوابط محدود نمیشود مگر آن که به موجب قانون ممنوع و محدود شده باشد. 
<br/>
* با توجه به این بند و تا حدی که توسط قانون مجاز است:
تمام شرایط، تضمینها، ضمانتنامهها، نمایندگیها یا شرایطی که به طور واضح در این شرایط بیان نشدهاند، حذف میشوند.
تیم ما مسئولیتی در قبال موارد زیر نخواهد داشت:
<br/>
*هر گونه خسارت یا آسیب مگر این که این زیان یا آسیب قابل پیشبینی باشد و یا در نتیجه ناتوانی تیم ما در حمایت از مصرف کننده رخ داده باشد.
<br/>
* از دست رفتن سود یا فرصت یا آسیب به حسن نیت که ناشی از یا در ارتباط با خدمات وبسایت یا این ضوابط و شرایط است از جمله به علت عدم امکان استفاده از خدمات یا عرضه دیرین خدمات خرید.
<br/>
*ریسکها و خطرات استفاده از وبسایت، خدمات و هر یک از محصولات تیم ما  به همراه خدمات تحویل به عهده خود شخص است. همه چیز در وبسایت، خدمات و محصولات تیم ما، بر اساس "همانطور که هست" و "به صورت موجود" ارائه میشود.
<br/>
*قیمت محصولات و خدمات بدون اطلاع رسانی قبلی میتواند تغییر کند و در این مورد وبسایت مسئولیتی در برابر کاربران نخواهد داشت.
<br/>
*امکان تغییر یا قطع سرویس در هر زمانی بدون اطلاع رسانی قبلی امکان پذیر است و در این مورد وبسایت مسئولیتی در برابر کاربران نخواهد داشت.
<br/>
*تیم ما این حق را دارد که فروش محصولات یا خدمات را به هر کس یا منطقه خاص جغرافیایی محدود کند و این حق را ممکن است براساس ملاحظات موردی انجام دهد و در این مورد مسئولیتی نخواهد داشت.
<br/>
*تیم ما تضمین و تایید نمیکند که استفاده شما از خدمات وبسایت بدون وقفه، به موقع، امن و بدون خطا است. و در این مورد مسئولیتی به عهده تیم ما   نیست. 
<br/>
*تیم ما  تضمین نمیکند که نتایجی که ممکن است از استفاده از وبسایت و خدمات آن به دست بیاید، دقیق و قابل اعتماد خواهد بود. شما نیز میپذیرید که گاهی اوقات تیم ما میتواند خدمات را برای مدت زمان مشخصی تعویض کند یا سرویس را در هر زمان بدون اطلاع قبلی به شما لغو کند. 
<br/>
*ممکن است اطلاعاتی در وبسایت و یا در خدمات وجود داشته باشد که همراه با خطاها و اشتباهاتی باشد و یا اینکه اشکالاتی مرتبط یا توضیحات محصولات و یا در قسمت درخواستها وجود داشته باشد. این حق برای تیم ما محفوظ است که این اشتباهات و اشکالات را برطرف کرده و این موارد را تغییر داده و بروزرسانی کند و حتی ممکن است درخواستی را به جهت وجود این اشکالات بدون اطلاع قبلی رد کند و از این جهت مسئولیتی متوجه این ما  نیست.  
این اطلاعات و به بروزرسانیها را هر جا که نیاز باشد، مطابق با قانون انجام میدهیم.
محدودیت مسئولیت:
<br/>
* مسئولیت کل تیم ما ناشی از یا در ارتباط با خدمات یا این شرایط و ضوابط است. 
<br/>
* تیم ما هیچ گونه مسئولیتی برای هر محتوایی که در وبسایت قرار می گیرد یا در ارتباط با خدمات است، ندارد، اعم از اینکه توسط کاربران وب سایت، توسط اشخاص ثالث یا توسط هر یک از کسانی که خدمات به آنها  ارائه شده است، ایجاد یا ارسال شده باشد.
<br/>
* لینکهای ثالث موجود در این وبسایت ممکن است مستقیما شما را به سایتهایی هدایت کند که به این ما وابسته نیستند. ما این سایتها را تضمین نمیکنیم و هیچ مسئولیتی در قبال این سایتها در برابر کاربران نداریم. همچنین مسئول ضرر و خسارتی که به واسطه استفاده از محصولات، خدمات و یا سایتهای ثالت وارد میشود هم نیستیم.
خاتمه قرارداد :
<br/>
* این شرایط تا زمانی که قرارداد توسط شما یا توسط تیم ما به پایان برسد به شرح زیر اعمال خواهد شد.
<br/>
-اگر شما میخواهید که به این شرایط و ضوابط پایان دهید می بایست به موارد زیر عمل کنید:
<br/>
*اطلاع دادن به تیم ما در هر زمان از طریق ثبت درخواست کتبی در قسمت ارتباط با ما که در صفحه اصلی وبسایت موجود است.  
<br/>
*بستن حسابهای شما برای تمام خدماتی که شما استفاده میکنید، از طریق قسمتی که تیم ما این گزینه را برای شما قرار داده است.
تیم ما ممکن است در هر زمان این شرایط و ضوابط را با شما خاتمه دهد اگر:
<br/>
*شما هر یک از مقررههای این شرایط و ضوابط را نقض کردهاید یا قصد نقض هر یک از مقررات را دارید.
<br/>
*زمانی که تیم ما به موجب قانون ناگزیر از این اقدام است.
<br/>
*شریکی که از طریق او تیم ما خدماتش را به شما ارائه میداد به رابطهاش یا تیم ما خاتمه داده باشد و یا ارائه خدمات خرید را به شما متوقف کرده باشد. 
<br/>
*ارائه خدمات به شما بنا به صلاحدید تیم ما دیگر قابل اعتماد نباشد. 
<br/>
- مطابق با قوانین لازم الاجرا تیم ما این حق را دارد که عضویت شما را در وبسایت متوقف یا لغو کند. اگر شما مقررات لازم الاجرا را نقض کنید، رفتار شما بر نام یا شهرت تیم ما تاثیر سو بگذارد و یا حقوق افراد دیگر را نقض کنید، ممکن است به صلاحدید خود، دسترسی شما را به کلیه یا هر بخش وبسایت یا خدمات بدون اطلاع شما متوقف کند.
<br/>
- هنگامی که التزام طرفین به این شرایط و ضوابط به پایان میرسد، تمام حقوق قانونی، تعهدات و مسئولیتهایی که شما و تیم ما از آن برخوردار بودهاید، باید با این توقف متوقف شود.
<br/>
نظرات کاربران:
<br/>
*اگر در هر زمانی بدون درخواست با درخواست ما شما از طریق ایمیل، به صورت آنلاین و از هر راه متداول دیگری نظری را ارسال نمایید، این حق برای ما محفوظ است که بدون ویرایش و اعمال محدودیت آن را در هر رسانهای استفاده کنیم و در برابر این نظرات ملزم به محفوظ نگه داشتن، جبران خسارت در صورت فاش شدن و پاسخ به همه آنها نیستیم.
<br/>
* ما این اختیار را داریم که بنا به صلاحدید خود هر محتوایی را که غیر قانونی، تهاجمی، همراه با تهدید، مختل کننده حقوق دیگران و در تضاد با این شرایط و ضوابط باشد، ویرایش و یا حذف کنیم.
<br/>
* شما میپذیرید که نظرات شما غیر قانونی، تهاجمی، همراه با تهدید و در تضاد با حقوق دیگران و این شرایط و ضوابط نباشد. در واقع شما مسئولیت هر ایمیلی را که از طرف شما ارسال میشود، به عهده دارید.
<br/>
جبران خسارت:
<br/>
شما میپذیرید که در برابر تیم ما، کارکنان، نمایندگیها و تامین کنندگان ثالثش در موارد زیر جبران خسارت کنید:
<br/>
*تمام اقدامات، ادعاها، خواستهها، هزینهها، تلفات و خساراتی که نشات گرفته یا ایجاد شده به وسیله یا در ارتباط با هر محتوایی است که از طریق وبسایت پست میکنید.
<br/>
*هرگونه پیامد مستقیم یا غیرمستقیم از دسترسی، استفاده یا انجام معاملات در وبسایت
<br/>
*نقض هر یک از موارد این شرایط و ضوابط
<br/>
حل و فصل اختلافات:
<br/>
موارد الزامی: 
<br/>
در صورتی که مناقشات ناشی از شرایط و ضوابط یا مربوط به این شرایط باشد، هر یک از طرفین با رعایت موارد زیر میتواند اقدام به رسیدگی قضایی به اختلافات نماید. به جز مواردی که باید امدادرسانی فوری صورت بگیرد.
<br/>
توجه کنید که:
<br/>
طرفی که ادعا میکند اختلافی در ارتباط با این شرایط مطرح شده است، باید یک نوشته کتبی به طرف دیگر ارسال کند تا جزئیات ماهیت اختلاف، نتیجه مورد نظر و اقدام مورد نیاز برای حل اختلاف را مشخص کند.
<br/>
طرف مقابل باید:
<br/>
*ظرف مدت 10 روز از  دریافت اظهارنامه برای حل و فصل اختلافات از طریق مذاکره و یا به روشهای دیگری که با آنها موافقت میکنند، اقدام کنند. 
<br/>
*اگر به هر دلیلی ظرف مدت 10 روز از تاریخ دریافت اظهارنامه اختلافات حل و فصل نشد، دو طرف اختلاف باید یا بر انتخاب داور از طرف خودشان توافق کنند و یا درخواست کنند که یک داور مناسب برای آنها تعیین شود.
<br/>
*طرفین به طور برابر در برابر هزینههای داوری مسئول هستند. 
<br/>
*مکان حل و فصل اختلافات به وسیله داور نیز باید مشخص شود. 
<br/>
محرمانگی:
<br/>
تمامی ارتباطات مربوط به مذاکرات که از اختلافات نشات میگیرند یا مرتبط به آنها هستند، محرمانه میباشند و تا حدی که ممکن است این مذاکرات باید بدون غرض ورزی صورت بگیرد.
<br/>
چنانچه ظرف مدت تعیین شده از آغاز داوری برای حل و فصل اختلافات به وجود آمده سپری شود ولی همچنان اختلاف باقی باشد، در این صورت هر یک از طرفین میتواند اتمام داوری را خواستار شود و داور نیز ملزم به پذیرش این مسئله میباشد.
<br/>
صلاحیت محلی:
<br/>
*خدمات این وبسایت قابل ارائه به افرادی است که در ایران زندگی میکنند و لذا در صورت بروز هر گونه اختلاف در ارتباط با این وبسایت دادگاههای قلمرو این کشور صالح به رسیدگی هستند.
<br/>
قانون حاکم:
<br/>
شرایط و ضوابط پیشرو مطابق با قوانین ایران تنظیم شدهاند. هر گونه اختلاف یا ادعایی که مرتبط با این شرایط و ضوابط باشد، باید بر اساس این قانون تفسیر شود و حقوق طرفین و جانشینان آنها نیز باید حل و فصل شود.
<br/>
*هر دو طرف تایید میکنند که مفاد این شرایط و ضوابط منصفانه و منطقی است و اعلام میکنند که این شرایط و ضوابط خلاف سیاستهای عمومی و به صورت نابرابر تنظیم نشده است.
<br/>
* اگر بخشی از این شرایط و ضوابط براساس حکم دادگاه صلاحیتدار غیر قابل اجرا تشخیص داده شود، در این صورت تنها همین بخش از ضوابط و شرایط جدا شده و باقی مفاد لازم الاجرا باقی میماند.

            </div>
        </div>
    );
}

export default Textpolicy;