import React,{useState} from 'react';
import Textpolicy from './textpolicy';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

function Pagepolicy(){

    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return(
        <div>
           <Button variant="link" onClick={handleShow}>
         قوانین را قبول دارم
        </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>قوانین سایت</Modal.Title>
        </Modal.Header>
        <Modal.Body><Textpolicy /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            پذیرش
          </Button>
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default Pagepolicy;