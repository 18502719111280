import React, { Component} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import  { Redirect } from 'react-router-dom'


class QuizFormResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      copied: false,
      resp:null
    };
    this.setState({comment: 'Hello'}) 
  }
  
  

  render() {
    const { result } = this.props;
    
     
    if(result!==null){
      const qname = JSON.parse(result)['quizTitle'];
      const email = localStorage.getItem('email');
      const api_token = localStorage.getItem('api_token');
      
      //console.log(qname);


      const data = {'qjson':result,'email':email,'api_token':api_token,'qname':qname};

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept':'application/json' },
        body: JSON.stringify(data)
    };
    //console.log(requestOptions);
    
    fetch('https://api.quiz360.ir/public/api/v1/qustion', requestOptions)
    .then(response => response.json())
    .then(data1 => responseapi(data1) );

    function responseapi(data1){
      //console.log(data1)
      localStorage.setItem('examnumber', data1.data.examnumber);
      localStorage.setItem('qname', data1.data.qname);
      
      
    }
    //localStorage.setItem('api_token', data1.data.api_token);
      //console.log()
    }


    

    

    
    

    
    
      
    
        
          
    
    const style = {
      resultBox: {
        background: '#e1f5fe',
        minHeight: 260,
        padding: 20,
        margin: 20
      },
      button: {
        whiteSpace: 'nowrap',
        display: 'inline-block',
        borderRadius: '5px',
        padding: '10px 15px',
        margin: '0 0 20px 20px',
        fontSize: '20px',
        color: '#fff',
        backgroundImage: 'linear-gradient(#4f93ce,#285f8f)',
        border: '1px solid #285f8f',  
        cursor: 'pointer'
      }
    }
    //<Redirect to="/qwrite"/>
    return (
      <div className="QuizFormResult">
       	  {result != null? (
              <div>
                <Redirect to="/qwrite"/>
                <p>اگر این صفحه را می بینید احتمالا به مشکلی برخورده اید ولی نگران نباشید به احتمال زیاد سوال شما ذخیره شده و از صفحه سوالات طرح شده  می توانید آن را مشاهده کنید</p>
                <div style={style.resultBox}>
                 <pre> {result} </pre>
              </div>
              <div>
                <CopyToClipboard text={result}
                  onCopy={() => this.setState({copied: true})}>
                  <button style={style.button}>Copy to Clipboard</button>
                </CopyToClipboard> 
              </div>

              </div>
              

          ) : (
            null
          )}
      </div>
    );
  }
}

export default QuizFormResult;