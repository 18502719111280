import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from "react-google-recaptcha";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function Contactus() {
  const { register, handleSubmit, errors } = useForm();
  const TEST_SITE_KEY = "6Le5gewUAAAAADd8WRvFmxvlKk7aTm8Hd8MUXyDb";
  const [captcha,setcapcha] = useState();
  const [captchamessage,setcaptchamessage]= useState();
  const [resp,setresp] = useState();
  const [done,setdone] = useState(false); 


  const onSubmit = data => {

    if(captchamessage===null){
        setcaptchamessage(true)
    }
      //console.log(data);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept':'application/json' },
        body: JSON.stringify(data)
    };
    //console.log(requestOptions);
    if(captcha===true){
    fetch('https://api.quiz360.ir/public/api/v1/insertnewcontact', requestOptions)
        .then(response => response.json())
        .then(data1 => auth(data1) );
        //console.log(resp?.data.api_token)
  
        const auth = data1 => {  
      if(data1?.data?.externalnumber){
        
        
        setresp(data1?.data?.externalnumber);
        setdone(true);
        
      }
      else{
        setresp(data1.message)
        //console.log(data1.message)
        return true;
      }
    }
}else{setcaptchamessage(true)}
    
    }
  //console.log(errors);

  function onCaptch(value) {
    //console.log("Captcha value:", value);

    setcapcha(true);
    setcaptchamessage(false)
    if(value === null){
        setcapcha(false);
        setcaptchamessage(true)
    }
  }

  //console.log(captcha)
  
  return (
      <div>
      {done ? (<div className="text-center" style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>
      <div class="form-group alert alert-success" role="alert">
           پیام شما با موفقیت ارسال شد
           شماره پیگیری شما برابر است با {resp}
      </div>
      <br></br>
      <div class="form-group">
      <CopyToClipboard text={resp}>
        <input type="button" class="btn btn-outline-info" value="کپی" ></input>
    </CopyToClipboard>
    
    </div>
      </div>):(<div>
          <h2><span style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}} class="badge badge-secondary">تماس با ما</span></h2>
    <form onSubmit={handleSubmit(onSubmit)}>
        <div class="form-group">
        <label for="exampleInputEmail1">ایمیل</label>
            <input type="text" defaultValue={localStorage.getItem('email')} class="form-control" placeholder="ایمیل" name="email"  ref={register({required: {value:true,message:"این فیلد الزامی است"},maxLength:{value:40,message:"تعداد کارکتر ها بیش از حد مجاز است"} ,pattern: {value:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,message:"لطفا یک ایمیل معتبر وارد کنید"}})} />
            <div class="alert-danger">
                {errors?.email?.message}
      </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">موضوع</label>
      <input type="text" class="form-control" placeholder="موضوع" name="subject" ref={register({required: {value:true,message:"این فیلد الزامی است"}, maxLength: {value:35,message:"تعداد کارکتر ها بیش از حد مجاز است"}})} />
      <div class="alert-danger">
                {errors?.subject?.message}
      </div>
      </div>
      <div class="form-group">
        <label for="exampleInputEmail1">متن</label>
      <textarea name="text" placeholder="متن" class="form-control" rows="4" ref={register({required: {value:true,message:"این فیلد الزامی است"}, maxLength: {value:1000,message:"تعداد کارکتر ها بیش از حد مجاز است"}})} />
      <div class="alert-danger">
                {errors?.text?.message}
      </div>
      </div>
      <ReCAPTCHA
    sitekey={TEST_SITE_KEY}
    onChange={onCaptch}
  />
  <div class="form-group">
  {captchamessage ? (<div style={{textAlign:"center"}} class="alert alert-danger" role="alert">لطفا اعتبار سنجی را انجام دهید</div>):('')}
  </div>
      <input type="submit" class="btn btn-outline-success" value="ارسال" />
    </form>
    <div class="alert-warning" style={{textAlign:"center"}} role="alert">
    {resp}
    </div>
    </div>)}
    </div>
  );
}

export default Contactus;