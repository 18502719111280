import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import useAuthActions from "../hook/useAuthActions";
import useHistory from "../hook/useHistory";
import useAuth from "../hook/useAuth";
import {Link} from 'react-router-dom';

import loginicon from '../obj/login/login.png'


export default function Login() {
  const { register, handleSubmit, errors } = useForm();
  const [resp,setresp] = useState();
  
  const [loading,setloading] = useState(false);
  const [mailvery,setmailvery] = useState(true);
  const setLogin = useAuthActions();
  const history = useHistory();
  const loggedIn = useAuth()
  if(loggedIn===true){
    history.push("/profile")
  }
  //setLogin(true)
  //history.push("/profile")
  const onSubmit = data => {

    setloading(true);

    //console.log(data);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept':'application/json' },
      body: JSON.stringify(data)
  };
  //console.log(requestOptions);
  fetch('https://api.quiz360.ir/public/api/v1/login', requestOptions)
      .then(response => response.json())
      .then(data1 => auth(data1) );
      ////console.log(resp?.data.api_token)

      const auth = data1 => {  
    if(data1?.data?.api_token){
      setLogin(true)
      
      localStorage.setItem('api_token', data1.data.api_token);
      localStorage.setItem('email', data1.data.email);
      history.push("/profile")
    }
    else if(data1?.data?.data[0]==="2"){
      setmailvery(false);
      setloading(false);
    }
    else{
      setresp(data1)
      setloading(false);
      
      return true;
    }
  }
      //localStorage.getItem('rememberMe') === 'true';
      ////console.log(resp.data);
      //setresp(data1)
    
  
  };
  //console.log(errors);

  












  const divStyle = {
   // note the capital 'W' here
   ' display': '-ms-flexbox',
  'display': 'flex',
  '-ms-flex-align': 'center',
  'align-items': 'center',
  'padding-top': '80px',
  'padding-bottom':'80px',
  'position': 'relative',
  'box-sizing': 'border-box',
  'height': '100%',
  'font-size': '16px',
  'width': '100%',
  'max-width': '330px',
  'padding': '40px',
  'margin': 'auto'
  };
  
  return (
    <div className="text-center" style={divStyle}>
    <form  class="form-signin" onSubmit={handleSubmit(onSubmit)}>
    <img class="mb-4" src={loginicon} alt="" width="72" height="72" />
    <h1 class="h3 mb-3 font-weight-normal">ورود</h1>
    <label for="inputEmail" >ایمیل</label>
      <input type="text"class="form-control" placeholder="ایمیل" name="email" ref={register({required: {value:true,message:"این فیلد الزامی است"}, pattern: {value:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,message:"لطفا یک ایمیل معتبر وارد کنید"}})} />
      <div class="alert-danger">
                {errors?.email?.message}
      </div>
      <label for="inputPassword">رمز عبور</label>
      <input type="password" class="form-control" placeholder="رمز عبور" name="password" ref={register({required: {value:true,message:"این فیلد الزامی است"}})} />
      <div class="alert-danger">
                {errors?.password?.message}
      </div>
      <div class="alert-warning" role="alert"> 
      {resp?.data?.data ? ('رمز عبور یا نام کاربری اشتباه است'):('')}
      {!mailvery ? (<div>لطفا ابتدا ایمیل خود را فعال کنید</div>):('')}
      </div>
      {!loading ? (<input class="btn btn-lg btn-primary btn-block" value="ورود" type="submit" />):(<div class="d-flex justify-content-center"><button class="btn btn-outline-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  <span class="sr-only">درحال بارگیری ...</span>
</button></div>)}
<div class="d-flex justify-content-center">
      <Link class="btn btn-link" to="/resetpasswordreq">فراموشی رمز عبور</Link>
      {!mailvery ? (<div>
        <Link class="btn btn-link" to="/makenewveryfiy">ارسال دوباره لینک فعالسازی</Link>
         </div>):('')}
    </div>
    </form>
    
    </div>
  );
}