import React from "react"
import { Jumbotron, Container } from "reactstrap"
import Profilebutton from '../obj/profilepage/buttons'

function ProfilePage() {
  return (
    <div className="text-center">
      <Jumbotron>
        <Container>
          <Profilebutton />
          
        </Container>
      </Jumbotron>
    </div>
  )
}

export default ProfilePage
