import React,{useEffect,useState} from 'react';
import {useParams} from "react-router-dom";
import { JsonEditor } from 'react-json-edit';
import Quiz from "../obj/answer-page/lib/Quiz";
import  {Link} from 'react-router-dom';
import Info1 from '../obj/editqustion/info'


function Editqustion(){

    const[loading,setloading] = useState(false);
    const[done,setdone] = useState(false);
    const[button1,setbutton1] = useState(false);
    const[button2,setbutton2] = useState(false);
    const[button21,setbutton21] = useState(false);
    const[button3,setbutton3] = useState(false);
    const[teststatus,setteststatus] = useState(false);

    const[editedobject,seteditedobject] = useState()
    const[editedobject1,seteditedobject1] = useState(null)


    const[Resv,setResv] = useState()

    let { id } = useParams();

    useEffect(() => {

        const apitoken = localStorage.getItem('api_token');
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify({'api_token':apitoken,'examnumber':id})
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/showeditdataq', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            
            //console.log(data1.data)
        if(data1?.data.qjson){
            
            
            setloading(true);
            //console.log('getstatus')
            setResv(data1.data.qjson);
            
          
        }
    
        
      
      }
        
      }, []);

      

      const editobject = data =>{
        seteditedobject(data);
        setbutton3(true)
        setbutton1(false)
        setbutton2(false);
      }

      const doedit =()=> {

        setbutton21(true);
        const qname = editedobject['quizTitle'];
        //console.log(qname)
        const apitoken = localStorage.getItem('api_token');
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify({'api_token':apitoken,'examnumber':id,'qjson':editedobject,'qname':qname})
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/editdataqustion', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            
            //console.log(data1)
        if(data1?.data?.data[0]===1){
            
            
            setdone(true);
            //console.log('getstatus')
            setResv(data1);
            
          
        }else{
            setbutton21(false)
            
        }
    
      }
      }

      const dotest =()=> {
        
        setbutton1(true);
        setteststatus(true);
        
        seteditedobject1(editedobject);
      }

      const onCompleteAction =()=>{
        setbutton2(true);
      }

    return(
        <div>
            {!loading ? (
            <div className="text-center"> 
                <div class="spinner-grow text-success" style={{'width': '6rem','height': '6rem'}} role="status">
                    <span class="sr-only">در حال بارگیری</span>
                    
                </div>
                <br></br>
                <Link class="btn btn-outline-dark" to="/viewqustion" >بازگشت</Link>
            </div>
            ):(
            <div>
                {!done ? (
                <div className="text-center">
                    <Info1 />
                    
                    <JsonEditor value={Resv} propagateChanges={editobject}/>
                    {!button1 ? (<input class="btn btn-outline-warning" disabled={!button3} type="button" onClick={dotest} value="تست تغییرات" />):('')}
                    {button1 ? (<Quiz quiz={editedobject1} shuffle={true}  showInstantFeedback={true} continueTillCorrect={true} onComplete={onCompleteAction} />):('')}
                    
                    {button2 ? (<div>{!button21 ? (<input class="btn btn-outline-danger" type="submit" onClick={doedit} value="اعمال تغییرات" />):(
                    <button class="btn btn-outline-danger" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    ارسال ...
                    </button>
                    )}</div>):('')}
                    <div className="text-center">
                    <Link class="btn btn-outline-dark" to="/viewqustion" >بازگشت</Link>
                    </div>
                </div>
                ):(
                <div className="text-center">
                    <div class="alert alert-success" role="alert">
                        ویرایش با موفقیت انجام شد
                    </div>
                    <Link class="btn btn-outline-dark" to="/viewqustion" >بازگشت</Link>
                </div>
                )}
            </div>
            )}
        </div>
    );
}

export default Editqustion;