import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import  { Redirect } from 'react-router-dom'
import useHistory from "../hook/useHistory";
import {useParams} from "react-router-dom";

export default function Answer_info() {
  const { register, handleSubmit, errors } = useForm();
  const [resp,setresp] = useState();
  const history = useHistory();
  const [sendda,setsendda] = useState();
  let { id } = useParams();



  const onSubmit = data =>{
    setsendda(data)
    const email = localStorage.getItem('email');
    const api_token = localStorage.getItem('api_token');
    //********************************************* */

    const datasend2 = {'email':email,'api_token':api_token,'examnumber':data.examnumber};
        
        const requestOptions2 = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify(datasend2)
      };

      //console.log(requestOptions2);
      
      fetch('https://api.quiz360.ir/public/api/v1/recivequstion', requestOptions2)
          .then(response => response.json())
          .then(data1 =>setdataapi2(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi2 = data1 => {  
        if(data1?.data?.qjson){
          
          //setresp(data1.data.qjson)
          //console.log(data1)
          //setresp({'qjson':data1.data.qjson})
          ////console.log(data1.data.qjson)
          ////console.log(JSON.parse(data1.data.qjson))
          
          localStorage.setItem('qjson', data1.data.qjson)
          localStorage.setItem('examfinish',"0")
          
          
          ////console.log(setresp)
          //localStorage.setItem('answernumber', data1.data.answernumber);
          //history.push("/quizinfo/"+data.examnumber)
          //localStorage.setItem('email', data1.data.email);
          //return <Redirect to={{pathname: '/quizinfo/'+data.examnumber}}/>;
        }
        else{
          
          //console.log(data1)
          return true;
        }
        
      
      }

    //********************************************* */
    
    //console.log(data);
    const datasend = {'email':email,'api_token':api_token,'examnumber':data.examnumber,'private':data.private,'ajson':{'a':'s'}};
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept':'application/json' },
      body: JSON.stringify(datasend)
  };
  //console.log(requestOptions);
  fetch('https://api.quiz360.ir/public/api/v1/answer', requestOptions)
      .then(response => response.json())
      .then(data1 =>setdataapi(data1) );
      ////console.log(resp?.data.api_token)

      const setdataapi = data1 => {  
    if(data1?.data?.answernumber){
      
      setresp(data1)
      localStorage.setItem('answernumber', data1.data.answernumber);
      //history.push("/quizinfo/"+data.examnumber)
      //localStorage.setItem('email', data1.data.email);
      //return <Redirect to={{pathname: '/quizinfo/'+data.examnumber}}/>;
    }
    else{
      setresp(data1)
      //console.log(data1)
      return true;
    }
    
  
  }
}
  //console.log(errors);
  
  return (
     <div style={{'text-align': 'center'}} >
    <form class="form-signin" onSubmit={handleSubmit(onSubmit)}>
        <div class="form-row">
        <div class="form-group col-md-12">
            <label for="inputexamnumber4">شماره سوال</label>
        <div >
            <input type="text" defaultValue={id} class="form-control" placeholder="شماره سوال" name="examnumber" ref={register({required: {value:true,message: "این فیلد الزامی است"}})} />
            <div class="alert-danger">
                {errors?.examnumber?.message}
            </div>
      </div>
      </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
      <div>
            <input type="checkbox" placeholder="private" name="private" ref={register} />
            <label class="form-check-label" for="gridCheck">
                 آزمون محرمانه! 
            </label>
      </div>
      </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
            <input type="submit" class="btn btn-outline-secondary" value="تایید" />
        </div>
    </div>
    
            {resp?.data?.answernumber ? (<Redirect to={{pathname: '/quizinfo/'+sendda.examnumber}}/>) : ('')}
    </form>
    </div> 
  );
}