import React from 'react';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import {Row,Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {ListGroup} from 'react-bootstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';


import Button from 'react-bootstrap/Button';

function Card1(props){

    return(
        <CardDeck>
            <CardGroup>
        
            <div class="col-lg-4"> 
                <Card style={{ width: '20rem' }}>
                <Card.Body>
                <ListGroup.Item>{props.title}</ListGroup.Item>
                <ListGroup.Item>{props.id1}</ListGroup.Item>
                <CopyToClipboard text={props.id2copy}>
                <ListGroup.Item>{props.id2}</ListGroup.Item>
                </CopyToClipboard>
                <ListGroup.Item>{props.id3}</ListGroup.Item>
                <ListGroup.Item ><Link  to={props.id5link}>{props.id5}</Link></ListGroup.Item>
                <ListGroup.Item><Link to={props.id4link}>{props.id4}</Link></ListGroup.Item>
                </Card.Body>
                </Card>
            </div>
            </CardGroup>
            </CardDeck>
    )
}

Card1.defaultProps = {

id1:"مقداری وجود ندارد",
id2:"مقداری وجود ندارد",
id3:"مقداری وجود ندارد",
id4:"مقداری وجود ندارد",
id5:"مقداری وجود ندارد",    
title:"Sample Title",
src:"https://homepages.cae.wisc.edu/~ece533/images/boat.png",
text:"Sample Text",
click:"1",
func:"بیشتر"
}

export default Card1;