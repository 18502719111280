import React from 'react';
import {Link} from 'react-router-dom';

function Body() {
  return (
    <main role="main" class="inner cover">
    <h3 class="cover-heading">اخبار و اطلاعیه</h3>
    <p class="lead"></p>
    <p class="lead">
    <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
      <Link class="btn btn-lg btn-secondary"  to="/publicpage"><small>سوالات عمومی</small></Link>
      <Link class="btn btn-lg btn-secondary"  to="/contactus"><small>تماس با ما</small></Link>
    </div>
    </p>
  </main>
  );

}

export default Body;
