import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import useAuth from "../hook/useAuth";
import useHistory from "../hook/useHistory";
import Pagepolicy from '../obj/policy/pagepolicy';


export default function Sign_up() {
  const { register, handleSubmit, errors } = useForm();
  const [resp,setresp] = useState();
  const [senddata,setsenddata] = useState();

  const [loading,setloading] = useState(false);
  const [done,setdone] = useState(false);

  //if auth
  const history = useHistory();
  const loggedIn = useAuth()
  if(loggedIn===true){
    history.push("/profile")
  }
  
  
  
  /*const onSubmit = data => {

    setsenddata(data);
    var myHeaders = new Headers();
myHeaders.append("Accept", "application/json");
myHeaders.append("Content-Type", "multipart/form-data; boundary=--------------------------368284775694516011295613");

var formdata = new FormData();
formdata.append('name','kjk');


//console.log(formdata);
var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: setsenddata,
  redirect: 'follow'
};
  
fetch("https://api.quiz360.ir/public/api/v1/register", requestOptions)
  .then(response => response.text())
  .then(result => //console.log(result))
  .catch(error => //console.log('error', error));
  }*/


  /*const onSubmit = data =>setsenddata(data);

  //console.log(errors);*/
  



//************************************** */



/******************************** */

const onSubmit = data =>{
  setsenddata(data);
  setloading(true)

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept':'application/json' },
      body: JSON.stringify(data)
  };
  fetch('https://api.quiz360.ir/public/api/v1/register', requestOptions)
      .then(response => response.json())
      .then(data1 => setdataapi(data1));

      const setdataapi = data1 => {
        
        setresp(data1);
        if(data1?.data?.api_token){
          setdone(true);
        }
        else{
          setloading(false);
        }
        

  }
      //console.log(resp);
      
}
    



  
  return (
      <div >
          <form  onSubmit={handleSubmit(onSubmit)}>
          <div class="form-row">  
          <div class="form-group col-md-5">
            <label for="inputName4">نام</label> 
            <input class="form-control" type="text" placeholder="نام" name="name" ref={register({required: {value:true,message: "این فیلد الزامی است"}, maxLength: {value:25,message:"نام شما بیش از حد طولانی است"}, pattern: {value:/^[\u0600-\u06FF\s]+$/,message:"لطفا نام خود را فارسی وارد کنید"}})} />
            <div class="alert-danger">
                {errors?.name?.message}
            </div> 
          </div>
          <div class="form-group col-md-5">
          <label for="inputLastname4">نام خانوادگی</label>  
            <input class="form-control"  type="text" placeholder="نام خانوادگی" name="lastname" ref={register({required: {value:true,message:"این فیلد الزامی است"}, maxLength: {value:25,message:"نام خانوادگی شما بیش از حد طولانی است"}, pattern: {value:/^[\u0600-\u06FF\s]+$/,message:"لطفا نام خانوادگی خود را فارسی وارد کنید"}})} />
            <div class="alert-danger">
                {errors?.lastname?.message}
            </div>
          </div>
          <div class="form-group col-md-2">
            <label for="inputEmail4">نوع کاربری</label>
            <select class="form-control" name="type" ref={register({ required: {value:true,message:"لطفا نوع کاربری خود را وارد کنید"} })}>
              <option selected></option>
              <option value="1">آزمون دهنده</option>
              <option value="2">طراح سوال</option>
              <option value="3">طراح و آزمون دهنده</option>
            </select>
            <div class="alert-danger">
                {errors?.type?.message}
            </div>  
          </div>
          </div>
          <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputEmail4">ایمیل</label>    
            <input class="form-control" type="text" placeholder="ایمیل" name="email" ref={register({required: {value:true,message:"این فیلد الزامی است"},pattern: {value:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,message:"لطفا یک ایمیل معتبر وارد کنید"} ,maxLength: {value:80,message:"ایمیل وارد شده بیش از حد طولانی است"}})} />
            <div class="alert-danger">
                {errors?.email?.message}
            </div>
          </div>
          <div class="form-group col-md-2">
          <label for="inputCity4">استان</label>  
      <select class="form-control" name="city" ref={register({ required: {value:true,message:"لطفا شهر خود را وارد کنید"} })}>
        <option selected></option>
        <option value="Alborz">البرز</option>
        <option value="Ardabil">اردبیل</option>
        <option value="AzerbaijanEast">آذربایجان شرقی</option>
        <option value="AzerbaijanWest">آذربایجان غربی</option>
        <option value="Bushehr">بوشهر</option>
        <option value="ChaharMahaalandBakhtiari">چهارمحال و بختیاری</option>
        <option value="Fars">فارس</option>
        <option value="Gilan">گیلان</option>
        <option value="Golestan">گلستان</option>
        <option value="Hamadan">همدان</option>
        <option value="Hormozgān">هرمزگان</option>
        <option value="Ilam">ایلام</option>
        <option value="Isfahan">اصفهان</option>
        <option value="Kerman">کرمان</option>
        <option value="Kermanshah">کرمانشاه</option>
        <option value="KhorasanNorth">خراسان شمالی</option>
        <option value="KhorasanRazavi">خراسان رضوی</option>
        <option value="KhorasanSouth">خراسان جنوبی</option>
        <option value="Khuzestan">خوزستان</option>
        <option value="KohgiluyehandBoyerAhmad">کهگیلویه و بویراحمد</option>
        <option value="Kurdistan">کردستان</option>
        <option value="Lorestan">لرستان</option>
        <option value="Markazi">مرکزی</option>
        <option value="Mazandaran">مازندران</option>
        <option value="Qazvin">قزوین</option>
        <option value="Qom">قم</option>
        <option value="Semnan">سمنان</option>
        <option value="SistanandBaluchestan">سیستان و بلوچستان</option>
        <option value="Tehran">تهران</option>
        <option value="Yazd">یزد</option>
        <option value="Zanjan">زنجان</option>
      </select>
      <div class="alert-danger">
          {errors?.city?.message}
      </div>
      </div>
      <div class="form-group col-md-4">
          <label for="inputPassword4">رمز عبور</label>
          <input type="password" class="form-control" placeholder="رمز عبور" name="password" ref={register({required: {value:true,message:"این فیلد الزامی است"},minLength: {value:8,message:"کلمه عبور شما کوتاه است"} ,maxLength: {value:50,message:"کلمه عبور بیش از حد بزرگ است"}})} />
          <div class="alert-danger">
          {errors?.password?.message}
      </div>
      </div>  
      </div>
      
      <div class="form-row">
        <div class="form-group col-md-2">    
            <div class="form-check">
        <input type="checkbox" class="form-check-input" placeholder="قوانین را قبول دارم"  name="ploicy" ref={register({required: {value:true,message:"برای ثبت نام باید قوانین را قبول داشته باشید"}})} />
        <label class="form-check-label" for="gridCheck">
        <Pagepolicy />
      </label>
      <div class="alert-danger">
          {errors?.ploicy?.message}
      </div>
      </div>
      </div>
      <div class="form-group col-md-3">
      {!loading ? (<input type="submit" class="btn btn-outline-secondary" value='ثبت نام'  />):(<div>{!done ? (<button class="btn btn-secondary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  <span class="sr-only">درحال بارگیری ...</span>
</button>):('')}</div>)}
      </div>
      </div>
      <div class="alert-success" role="alert">
      {resp?.data?.name ? (' ثبت نام با موفقیت انجام شد و ایمیل فعال سازی برای شما ارسال شد') : ('')}
      </div>
      <div class="alert-warning" role="alert">
      {resp?.message ? (' ایمیل وارد شده تکراری است یا خطایی رخ داده') : ('')}
      </div>
    </form>
      </div>
  );
}
