import React from 'react';
import './styles/bootstrap.css';
import './styles/bootstrap-rtl.css'
import NavLink from './route/link';
import Route1 from './route/route';
import Footer from './obj/first/footer';
import AuthProvider from "./obj/login/AuthProvider";


function App() {
  return (
    <AuthProvider>
    <div>
      <div class="container">
      <NavLink />
      
      <Route1 />
      </div>
      <br />
      <Footer />
    </div>
    </AuthProvider>
  );
}

export default App;
