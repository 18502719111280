import React,{useState,useEffect} from 'react'
import Quiz from "../obj/answer-page/lib/Quiz";
import {quiz} from '../obj/answer-page/docs/quiz';
import {useParams} from "react-router-dom";
import {quiz1} from '../obj/answer-page/docs/quiz1'
import  { Redirect } from 'react-router-dom'
import useHistory from "../hook/useHistory";
import {CopyToClipboard} from 'react-copy-to-clipboard';



function Quiz_info(){
    let { id } = useParams();
    const [resp,setresp] = useState(localStorage.getItem('qjson')!=='' ? (JSON.parse(localStorage.getItem('qjson'))) : (0));
    const [examnumber,setexamnumber] = useState(id);

    const [answernumber,setanswernumber] = useState(localStorage.getItem('answernumber'));

    const [examfinish,setexamfinish] = useState()

    const history = useHistory();

    

    //console.log(localStorage.getItem('qname'))
    
    if(localStorage.getItem('qname')===''){
      //console.log('done')
    }

    
    const onCompleteAction = (obj) => {
      //console.log(obj);

      //localStorage.setItem('examfinish',"1")
      const an = localStorage.getItem('answernumber')
      const email = localStorage.getItem('email');
      const api_token = localStorage.getItem('api_token');
      const datasend = {'email':email,'api_token':api_token,'answernumber':an,'ajson':obj};
    
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Accept':'application/json' },
        body: JSON.stringify(datasend)
    };
    //console.log(requestOptions);
    
    if(localStorage.getItem('examfinish')==='0'){
    fetch('https://api.quiz360.ir/public/api/v1/completeanswer', requestOptions)
        .then(response => response.json())
        .then(data1 =>setdataapi2(data1) );

        const setdataapi2 = data1 => {

          

          if(data1?.data?.data){

            //console.log(data1?.data?.data[0])

            if(data1?.data?.data[0]==="0"){
              //console.log('done')

              //comeback to profile 
            }

          }

        }
        //console.log("ifdone")
        
          
        }
        
        //1643858030593626950
        ////console.log(resp?.data.api_token)
        
        
        localStorage.setItem('examfinish',"1")
  
      
        
    
      return (<div>
      <div style={{'text-align': 'center'}}>
              <CopyToClipboard text={an}>
              <button class="btn btn-secondary">کپی</button>
            </CopyToClipboard>
        <div class="alert alert-dark" role="alert">
        <div> شماره آزمون :{an}  </div>
        <div> افراد دیگر با داشتن این شماره می توانند آزمون شما را مشاهده کنند یا اگر آزمون شما اتمام ثبت نشد می تواند به آزمون بازگردید </div>
        </div>

          <input type="button" class="alert alert-success" value="پروفایل" onClick={handelpush} />
          </div>
    </div>);
    }



    const handelpush = () =>{
      history.push("/profile");
    }


    return(
        
        <div>
            {!resp ? (
                <div class="d-flex justify-content-center">
                <div class="spinner-border text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              </div>
            ) : (
              <div>
                {onCompleteAction}
                <Quiz quiz={resp} shuffle={true}  showInstantFeedback={true} continueTillCorrect={true}   onComplete={onCompleteAction} />
                
            
            </div>
            )}
        </div>
    );
}

export default Quiz_info;