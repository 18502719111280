import React from 'react';
import {ListGroup} from 'react-bootstrap';
import CardDeck from 'react-bootstrap/CardDeck';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Link} from 'react-router-dom';

function List3(props){



    return(
      <CardDeck>
            <CardGroup>
        
            <div class="col-lg-4"> 
                <Card style={{ width: '20rem' }}>
                <Card.Img variant="top" src={props.src} />
                <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                {props.text}
                </Card.Text>
    <Link to={`/product/`+props.click} class="btn btn-primary btn-lg">{props.func}</Link>
                </Card.Body>
                </Card>
            </div>
            </CardGroup>
            </CardDeck>
      
    
  
    )
}

List3.defaultProps = {

title:"Sample Title",
id1:"مقداری وجود ندارد",
id2:"مقداری وجود ندارد",
id3:"مقداری وجود ندارد",
id4:"مقداری وجود ندارد",
id5:"مقداری وجود ندارد",
id12:"مقداری وجود ندارد",
src:"https://homepages.cae.wisc.edu/~ece533/images/boat.png",
text:"Sample Text",
click:"1",
func:"بیشتر"
}

export default List3;