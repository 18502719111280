export const defaultLocale =  {
    "landingHeaderText": "<questionLength> تعداد سوالات",
    "question": "سوال",
    "startQuizBtn": "شروع آزمون",
    "resultFilterAll": "همه",
    "resultFilterCorrect": "پاسخ های درست",
    "resultFilterIncorrect": "پاسخ های غلط",
    "nextQuestionBtn": "بعدی",
    "resultPageHeaderText": "شما آزمون را به پایان رساندید. تعداد <correctIndexLength> از <questionLength>   سوال را درست جواب دادید.",
    "resultPagePoint": "نمره شما <correctPoints> از <totalPoints>."
  } 