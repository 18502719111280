import React from 'react';
import '../../styles/header.css';
import {Link} from 'react-router-dom';
import useAuth from "../../hook/useAuth";
import logo from  './img/quiz360.svg'
import {faHome} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Hrader() {
  const loggedIn = useAuth()
  return (
    <div>
        <header class="blog-header py-3">
    <div class="row flex-nowrap justify-content-between align-items-center">
      <div class="col-4 pt-1">
        {loggedIn ? <Link to="/profile" class="btn btn-outline-primary"> پروفایل</Link>  : <div class="btn-group"><Link class="btn btn-outline-primary" to="/sign_up"><small>ثبت نام</small></Link><Link class="btn btn-primary"  to='/'><small><FontAwesomeIcon icon={faHome} /></small></Link></div> }
      </div>
      <div class="col-4 text-center">
        <img src={logo} alt="quiz 360" />
      </div>
      <div class="col-4 d-flex justify-content-end align-items-center">
        
        {loggedIn ? <Link class="btn btn-sm btn-outline-secondary" to="/logout">خروج</Link> : <Link class="btn btn-sm btn-outline-secondary" to="/login">ورود</Link>}
      </div>
    </div>
  </header>
    </div>
  );
}

export default Hrader;
