import React,{useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import List3 from './list3'
import Card1 from './card'
import CardGroup from 'react-bootstrap/CardGroup';
import CardColumns from 'react-bootstrap/CardColumns';

function Publicpagescroller(){

    const[loading,setloading] = useState(true);
    const[loading2,setloading2] = useState(false);
    const[done,setdone] = useState(false);
    const[hasMore,sethasMore] = useState(true);
    const[Resv,setResv] = useState([]);
    const[nextpage,setnextpage] = useState(1);
    const[nextpage2,setnextpage2] = useState(0);


    const loadmore = (e)=>{

        //console.log(e)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
        };
        //console.log(requestOptions);
        fetch('https://api.quiz360.ir/public/api/v1/publicpage?page='+nextpage, requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );
            ////console.log(resp?.data.api_token)
      
            const setdataapi = data1 => {
                
                //console.log(data1)
                if(nextpage===data1.meta.last_page){
                    sethasMore(false)
                }

                setResv([...Resv,...data1.data]);
                setloading2(true)
                setnextpage(nextpage+1);
                setloading(true)
                

                
                
              //console.log(Resv)

      
        }

    }

    return(

                <div   >
                    <InfiniteScroll
                        className="row rtl"
                        pageStart={1}
                        loadMore={loadmore}
                        hasMore={hasMore}
                        loader={
                            <div><br></br><div class="spinner-border"  role="status">
                            <span class="sr-only">Loading...</span>
                          </div></div>
                    }
                    >

                    <div >
                    {Resv.map((item,index)=>{return <Card1 key={index} title={item.qname} />})}
                    </div>
                        
                    </InfiniteScroll>
                
                    
                </div>
                
    );
}

export default Publicpagescroller;