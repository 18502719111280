import React from 'react';
import  { Redirect } from 'react-router-dom';

function P404(){

    return(
        <div>
            <Redirect to="profile" />
            <div class="alert alert-danger" role="alert">
                <p>صفحه مورد نظر یافت نشد</p>
            </div>
        </div>
    );
}

export default P404;