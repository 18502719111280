import React,{useState,useEffect} from 'react';
import List from '../obj/Viewanserresult/list';
import moment from 'jalali-moment';
import {useParams,Link} from "react-router-dom";

function Answerresultshow(){
    let { id } = useParams();
    const[loding,setloding] = useState(false);
    const[Data,setData] = useState([]);



    useEffect(() => {

        
        
        const api_token = localStorage.getItem('api_token');
        const datasend = {'answernumber':id,'api_token':api_token};
        
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify(datasend)
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/showresultwithansn', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            //console.log(data1)
            
            
            
            
              
        if(data1?.data?.answernumber){
            
            setData(data1.data)
            setloding(true)
          
        }
    
        
      
      }
        
      }, []);

    return(
        <div className="text-center">
            {loding ? (
            <div className="row" style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>

    {[Data]?.map((item,index)=>{return <List  
    title={item.qname}
    id12={item.ajson===null ? ('این آزمون به پایان نرسیده'):([JSON.parse(item.ajson)].map((item1,index1)=>{return item1.totalPoints;}))}
    id1={item.ajson===null ? (''):("نمره: "+[JSON.parse(item.ajson)].map((item1,index1)=>{return item1.correctPoints;}))+" از "}
    id2={"شماره : "+item.answernumber}
    id2copy={item.answernumber}
    id3={item.private ? (<div class="alert-primary">آزمون خصوصی</div>):('آزمون عمومی')}
    id5={item.halfexam ? (<div class="alert-success" >ثبت شده</div>):(<div class="alert-danger" >نیمه تمام</div>)}
    id4={moment(item.updated_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}
    id5link={item.halfexam ? ('/Answerresultshow/'+id):('/Answerresultshow/'+id)}
    
      key={item.id} />;})}
      <br/>
     

            </div>
            ):(
            <div className="text-center">
                <div class="d-flex justify-content-center">
                <div class="spinner-border text-success" style={{width: '10rem', height: '10rem'}} role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                
                </div>
                <Link to='/publicpage' className="btn btn-outline-secondary" >بازگشت</Link>
                </div>
            
            )}
        </div>
    );
}

export default Answerresultshow;