import React from 'react'
import Publicpagescroller from '../obj/publicpage/publicpage1';
import Scroll from '../obj/publicpage/scrollinf';


function Publicpage(){

    return(

        <div className="text-center">

            
            <div>
                <Scroll />
            </div>
            
    
        </div>
    );
}

export default Publicpage;