import React from 'react'
import Pagation_result from '../obj/Viewqustion/pagation1';


function Viewqustion(){

    return(

        <div className="row" style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>

            
            <div>
                <Pagation_result />
            </div>
            
    
        </div>
    );
}

export default Viewqustion;