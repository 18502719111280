import React from 'react'
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';

function Info1(){

    return(
        <div>
    <Button color="primary" id="toggler" style={{ marginBottom: '1rem' }}>
      راهنما
    </Button>
    <UncontrolledCollapse toggler="#toggler">
      <Card>
        <CardBody>
        <div class="alert alert-primary" role="alert">
                        راهنمای ویرایش سوال
                        <br></br>
                        <span class="alert-light">quizTitle:عنوان سوال</span>
                        <span class="alert-dark">quizSynopsis:توضیحات سوال</span>
                        <span class="alert-light">question:صورت سوال</span>
                        <span class="alert-dark">answers:پاسخ سوال ها </span>
                        <span class="alert-light">correctAnswer:شماره سوال درست</span>
                        <span class="alert-dark">messageForCorrectAnswer:پیام سوال درست</span>
                        <span class="alert-light">messageForInorrectAnswer:پیام پاسخ اشتباه</span>
                        <span class="alert-dark">explanation:پاسخ سوال</span>
                        <span class="alert-light">point:نمره سوال</span>
                        <br></br>
                        توجه:
                        <br></br>
                        با موارد لاتین کاری نداشته باشید در غیر این صورت تست سوال دچار مشکل می شود و از ویرایش آن جلوگیری می شود
                        <br></br>
                        فقط پاسخ به سوالات اضافه کنید هر اضافه دیگری باعث خراب شدن شوال می شود
                        <br></br>
                        برای این که سوالات خراب نشوند قبل از اعمال تغییرات حتما تست می شوند

                    </div>
        </CardBody>
      </Card>
    </UncontrolledCollapse>
  </div>
    );
}

export default Info1;