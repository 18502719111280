import React from 'react'
import List from '../obj/Viewanserresult/list'
import Pagation_result from '../obj/Viewanserresult/pagation.js';


function Viewanserresult(){

    return(

        <div className="row" style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>

            
            <div>
            <Pagation_result />
            </div>
            
    
        </div>
    );
}

export default Viewanserresult;