import React,{useEffect,useState} from 'react';
import { useForm } from 'react-hook-form';

function Edituserinfo(){

    const { register, handleSubmit, errors } = useForm();
    const [resp,setresp] = useState();

    const [Resv,setResv] = useState([]);

    const[buttonload,setbuttonload] = useState(false);
    const[loading,setloading] = useState(false);
    const[done,setdone] = useState(false);
    const[status,setstatus] = useState(false);

    useEffect(() => {

        const apitoken = localStorage.getItem('api_token');
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify({'api_token':apitoken})
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/userinfo', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            
            //console.log(data1)
        if(data1?.data.name){
            
            
            setloading(true);
            //console.log('getstatus')
            setResv([data1.data]);
            
          
        }
    
        
      
      }
        
      }, []);


      const onSubmit = data =>{
          //console.log(data)
          setbuttonload(true);

          const apitoken = localStorage.getItem('api_token');

          const senddata = {'api_token':apitoken,'name':data.name,'lastname':data.lastname,'city':data.city,'type':data.type,'password':data.password}
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json','Accept':'application/json' },
          body: JSON.stringify(senddata)
      };
      //console.log(requestOptions);
      fetch('https://api.quiz360.ir/public/api/v1/edituserinfo', requestOptions)
          .then(response => response.json())
          .then(data1 =>setdataapi(data1) );
          ////console.log(resp?.data.api_token)
    
          const setdataapi = data1 => {
            
            //console.log(data1)
        if(data1?.data?.data[0]===1){
            
            setdone(true);
            
        }
        else{
            setbuttonload(false);
            setstatus(true);
        }
    
        
      
      }
      }






    return(
        <div>
           {!loading ? (
           <div class="text-center">
               <div class="spinner-grow text-success" style={{'width': '6rem','height': '6rem'}} role="status">
                    <span class="sr-only">در حال بارگیری</span>
               </div>
           </div>
           ):(
           <div>
               {!done ? (
               <div>
                   {Resv.map((item,index)=>{return <div>


                    <form  onSubmit={handleSubmit(onSubmit)}>
          <div class="form-row">  
          <div class="form-group col-md-5">
            <label for="inputName4">نام</label> 
            <input class="form-control" defaultValue={item.name} type="text" placeholder="نام" name="name" ref={register({required: {value:true,message: "این فیلد الزامی است"}, maxLength: {value:25,message:"نام شما بیش از حد طولانی است"}, pattern: {value:/^[\u0600-\u06FF\s]+$/,message:"لطفا نام خود را فارسی وارد کنید"}})} />
            <div class="alert-danger">
                {errors?.name?.message}
            </div> 
          </div>
          <div class="form-group col-md-5">
          <label for="inputLastname4">نام خانوادگی</label>  
            <input class="form-control" defaultValue={item.lastname} type="text" placeholder="نام خانوادگی" name="lastname" ref={register({required: {value:true,message:"این فیلد الزامی است"}, maxLength: {value:25,message:"نام خانوادگی شما بیش از حد طولانی است"}, pattern: {value:/^[\u0600-\u06FF\s]+$/,message:"لطفا نام خانوادگی خود را فارسی وارد کنید"}})} />
            <div class="alert-danger">
                {errors?.lastname?.message}
            </div>
          </div>
          <div class="form-group col-md-2">
            <label for="inputEmail4">نوع کاربری</label>
            <select class="form-control" defaultValue={item.type} name="type" ref={register({ required: {value:true,message:"لطفا نوع کاربری خود را وارد کنید"} })}>
              <option value="1">آزمون دهنده</option>
              <option value="2">طراح سوال</option>
              <option value="3">طراح و آزمون دهنده</option>
            </select>
            <div class="alert-danger">
                {errors?.type?.message}
            </div>  
          </div>
          </div>
          <div class="form-row">
          <div class="form-group col-md-3">
          <label for="inputCity4">استان</label>  
      <select class="form-control" defaultValue={item.city} name="city" ref={register({ required: {value:true,message:"لطفا شهر خود را وارد کنید"} })}>
        <option value="Alborz">البرز</option>
        <option value="Ardabil">اردبیل</option>
        <option value="AzerbaijanEast">آذربایجان شرقی</option>
        <option value="AzerbaijanWest">آذربایجان غربی</option>
        <option value="Bushehr">بوشهر</option>
        <option value="ChaharMahaalandBakhtiari">چهارمحال و بختیاری</option>
        <option value="Fars">فارس</option>
        <option value="Gilan">گیلان</option>
        <option value="Golestan">گلستان</option>
        <option value="Hamadan">همدان</option>
        <option value="Hormozgān">هرمزگان</option>
        <option value="Ilam">ایلام</option>
        <option value="Isfahan">اصفهان</option>
        <option value="Kerman">کرمان</option>
        <option value="Kermanshah">کرمانشاه</option>
        <option value="KhorasanNorth">خراسان شمالی</option>
        <option value="KhorasanRazavi">خراسان رضوی</option>
        <option value="KhorasanSouth">خراسان جنوبی</option>
        <option value="Khuzestan">خوزستان</option>
        <option value="KohgiluyehandBoyerAhmad">کهگیلویه و بویراحمد</option>
        <option value="Kurdistan">کردستان</option>
        <option value="Lorestan">لرستان</option>
        <option value="Markazi">مرکزی</option>
        <option value="Mazandaran">مازندران</option>
        <option value="Qazvin">قزوین</option>
        <option value="Qom">قم</option>
        <option value="Semnan">سمنان</option>
        <option value="SistanandBaluchestan">سیستان و بلوچستان</option>
        <option value="Tehran">تهران</option>
        <option value="Yazd">یزد</option>
        <option value="Zanjan">زنجان</option>
      </select>
      <div class="alert-danger">
          {errors?.city?.message}
      </div>
      </div>
      <div class="form-group col-md-4">
          <label for="inputPassword4"> رمز عبور جدید</label>
          <input type="password" class="form-control" placeholder="رمز عبور جدید" name="password" ref={register({required: {value:true,message:"این فیلد الزامی است"},minLength: {value:8,message:"کلمه عبور شما کوتاه است"} ,maxLength: {value:50,message:"کلمه عبور بیش از حد بزرگ است"}})} />
          <small id="emailHelp" class="form-text text-muted">مقدار قبلی نشان داده نمی شود فقط می توانید آن را تغییر دهید</small>
          <div class="alert-danger">
          {errors?.password?.message}
      </div>
      </div>  
      </div>
      
      <div class="form-row">

      <div class="form-group col-md-3">
      {!buttonload ? (<input type="submit" class="btn btn-outline-secondary" value='ویرایش'  />):(<div>{!done ? (<button class="btn btn-secondary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  <span class="sr-only">درحال بارگیری ...</span>
</button>):('')}</div>)}
      </div>
      </div>
      <div class="alert-warning" role="alert">
      {status ? ('خطایی رخ داده لطفا بعدا تلاش کنید') : ('')}
      </div>
    </form>


                   </div>;})}
               </div>
               ):(
               <div class="text-center">
                   <div class="alert alert-success" role="alert">
                        تغییرات با موفقیت اعمال شد
                    </div>
               </div>
               )}
           </div>
           )} 
        </div>
    );
}

export default Edituserinfo;