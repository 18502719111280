import React from 'react'
import Collapse_info from '../obj/quiz-form/button';
function Qwrite(){

    return(
        <div style={{'text-align': 'center'}}>
            <div class="alert alert-success" role="alert">
                <div>عملیات انجام شد</div>
                <Collapse_info />
            </div>
        </div>
    );
}

export default Qwrite;