import React,{useState} from 'react';
import { useForm } from 'react-hook-form';
import  { Redirect } from 'react-router-dom'
import useHistory from "../hook/useHistory";
import {useParams} from "react-router-dom";

export default function Viewanswerinfowithexn() {
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [sendda,setsendda] = useState();

  const [dataset,setdataset] = useState(false);
  const [errecv,seterrecv] = useState(false);

  let { id } = useParams();
  const [examnumberprams,setexamnumberprams] = useState(id);



  const onSubmit = data =>{
    setsendda(data)
    setdataset(true)

    
        

}
  //console.log(errors);
  
  return (
     <div style={{'text-align': 'center'}} >
    <form class="form-signin" onSubmit={handleSubmit(onSubmit)}>
        <div class="form-row">
        <div class="form-group col-md-12">
            <label for="inputexamnumber4"> شماره سوال</label>
        <div >
            <input type="text" defaultValue={examnumberprams} class="form-control" placeholder="شماره سوال" name="examnumber" ref={register({required: {value:true,message: "این فیلد الزامی است"}})} />
            <div class="alert-danger">
                {errors?.examnumber?.message}
            </div>
      </div>
      </div>
      </div>
      <div class="form-row">
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
            <input type="submit" class="btn btn-outline-secondary" value="تایید" />
        </div>
    </div>
    
            {dataset ? (<Redirect to={{pathname: '/viewanswerinfo/'+sendda.examnumber}}/>) : (<div>{errecv ? (<div class="alert alert-warning" role="alert">خطایی رخ داده است</div>) : ('')}</div>)}
    </form>
    </div> 
  );
}