import React,{useState} from 'react'
import { useForm } from 'react-hook-form';





function Resetpasswordreq(){
    const { register, handleSubmit, errors } = useForm();

    const[loading,setloading] = useState(false);
    const[done,setdone] = useState(false);
    const[status,setstatus] = useState(false);


    const onSubmit = data => {
        //console.log(data)
        setloading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','Accept':'application/json' },
            body: JSON.stringify(data)
        };
        //console.log(requestOptions);
        fetch('https://api.quiz360.ir/public/api/v1/requestrest', requestOptions)
            .then(response => response.json())
            .then(data1 =>setdataapi(data1) );
            ////console.log(resp?.data.api_token)
      
            const setdataapi = data1 => {
                setdone(true);
              //console.log(data1)
          if(data1?.data?.data[0]===1){
            setstatus(true)
              
            //console.log('getstatus')
          }
      
          
        
        }
    }

    return(
        <div>
            {!done ? (<form class="text-center" onSubmit={handleSubmit(onSubmit)}>
                <span class="badge badge-secondary">فراموشی رمز عبور</span>
        <div class="form-group">
        <label for="exampleInputEmail1">ایمیل</label>
            <input type="text" class="form-control" placeholder="ایمیل" name="email"  ref={register({required: {value:true,message:"این فیلد الزامی است"},maxLength:{value:40,message:"تعداد کارکتر ها بیش از حد مجاز است"} ,pattern: {value:/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,message:"لطفا یک ایمیل معتبر وارد کنید"}})} />
            <div class="alert-danger">
                {errors?.email?.message}
      </div>
      </div>
      {!loading  ? (<input type="submit" class="btn btn-outline-success" value="ارسال لینک بازنشانی رمز عبور" />):(<div class="text-center">
                
                <div class="spinner-grow text-success" style={{'width': '6rem','height': '6rem'}} role="status">
                  <span class="sr-only">در حال بارگیری</span>
                </div>
                            </div>)}
      </form>):(<div>
          {!status ? (<div class="text-center">
            <div class="alert alert-warning" role="alert">
                خطایی رخ داده لطفا بعدا امتحان کنید
            </div>
                   
          </div>):(<div class="text-center">
            <div class="alert alert-success" role="alert">
                لینک بازنشانی با موفقیت ارسال شد ،
                لطفا ایمیل خود را چک کنید
            </div>
          </div>)}
      </div>)}
        </div>
       
    );
}

export default Resetpasswordreq;