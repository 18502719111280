import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import validate from './validate';

class QuizForm extends Component {

  renderInputField = ({ input, label, type, meta: { touched, error } }) => (
    <div class="form-group">
      <label>{label}</label>
      <div>
        <input {...input} type={type} placeholder={label} />
        {touched && error && <span class="alert alert-danger">{error}</span>}
      </div>
    </div>
  );

  renderTextareaField = ({ input, label, type, meta: { touched, error } }) => (
    <div class="form-group">
      <label>{label}</label>
      <div>
        <textarea {...input} type={type} placeholder={label} />
        {touched && error && <span class="alert alert-danger">{error}</span>}
      </div>
    </div>
  );

   renderSelectField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div class="form-group">
      <label>{label}</label>
      <div>
        <select {...input} >
          {children}
        </select>
        {touched && error && <span class="alert alert-danger">{error}</span>}
      </div>
    </div>
  );
  
   renderSelectQuestionTypeField = ({ input, label, type, meta: { touched, error }, children }) => (
    <div class="form-group"> 
      <label>{label}</label>
      <div>
        <select {...input} >
          {children}
        </select>
        {touched && error && <span class="alert alert-danger">{error}</span>}
      </div>
    </div>
  );
  
   renderTextAnswers = ({ fields, question, meta: { error } }) => (
    <ul>
      <li style={{ listStyleType: "none"}}>
        <button class="btn btn-info" type="button" onClick={() => fields.push()}>اضافه کردن پاسخ</button>
      </li>
      {fields.map((answer, index) => (
        <li style={{ listStyleType: "none"}} key={index}>
          <input
            type="button"
            title="حدف پاسخ"
            onClick={() => fields.remove(index)}
            value="حذف پاسخ"
            class="btn btn-danger"
          />
          <Field
            name={answer}
            type="text"
            component={this.renderInputField}
            label={`پاسخ شماره ${index + 1}`}
            class="alert alert-info"
          />
        </li>
      ))}
          <li style={{ listStyleType: "none"}}>
           <Field
            name={`${question}.correctAnswer`}
            component={this.renderSelectField}
            label="پاسخ درست"
            class="form-control"
          >
            <option class="form-control" value="">لطفا پاسخ درست را انتخاب کنید</option>
             {fields.map((answer, index) => (
               <option class="form-control" key={index+1} value={index+1}>{`پاسخ شماره ${index + 1}`}</option>
             ))}
          </Field>
          </li>
    
      {error && <li style={{ listStyleType: "none"}} class="alert alert-danger">{error}</li>}
    </ul>
  );
  
  
   renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <ul  >
      <li  style={{ listStyleType: "none"}}>
        <button type="button" class="btn btn-primary" onClick={() => fields.push({})}>اضافه کردن سوال</button>
        {(touched || submitFailed) && error && <span>{error}</span>}
      </li>
      {fields.map((question, index) => (
        <li class="alert alert-success" style={{ listStyleType: "none"}} key={index}>
          <input
            type="button"
            title="Remove Question"
            onClick={() => fields.remove(index)}
            value="حذف سوال"
            class="btn btn-danger"
            
          />
          <h4 class="alert alert-dark">سوال  شماره {index + 1}</h4>
          <Field
            name={`${question}.question`}
            type="text"
            component={this.renderInputField}
            label="عنوان سوال"
            class="form-control"
          />
          <Field
            name={`${question}.questionType`}
            component={this.renderSelectQuestionTypeField}
            label="نوع سوال"
          >
            <option class="form-control" value="">لطفا نوع سوال را انتخاب کنید</option>
            <option value="text">متن</option>
            <option value="photo">تصویر</option>
          </Field>
          <FieldArray class="form-group" name={`${question}.answers`} component={this.renderTextAnswers} question={question} />
          <Field
            name={`${question}.messageForCorrectAnswer`}
            type="text"
            component={this.renderTextareaField}
            label="پیام برای پاسخ درست"
            class="form-control"
          />
          <Field
            name={`${question}.messageForInorrectAnswer`}
            type="text"
            component={this.renderTextareaField}
            label="پیام برای پاسخ اشتباه"
            class="form-control"
          />
          <Field
            name={`${question}.explanation`}
            type="text"
            component={this.renderTextareaField}
            label="پاسخ سوال"
            class="form-control"
          />
           <Field
            name={`${question}.point`}
            type="number"
            component={this.renderInputField}
            label="نمره سوال"
          />
        </li>
      ))}
    </ul>
  );

  render() {

  const { handleSubmit, pristine, reset, submitting } = this.props;
  const style = {
    resultBox: {
      'text-align': 'center'
    }
  }
    return (
      <div style={style.resultBox}>
         <form name="quiz-form" onSubmit={handleSubmit}>
	      <Field
	        name="quizTitle"
	        type="text"
	        component={this.renderInputField}
          label="عنوان سوال"
          class="form-control"
	      />
        <Field
	        name="quizSynopsis"
	        type="text"
	        component={this.renderTextareaField}
          label="توضیحات"
          class="form-control"
          
	      />
	      <FieldArray name="questions" component={this.renderQuestions} />
        <div class="row">
	      <div class="col">
	        <button class="btn btn-success" type="submit" disabled={submitting}>ثبت</button>
          </div>
          <div class="col">
	        <button class="btn btn-dark" type="button" disabled={pristine || submitting} onClick={reset}>
	          پاک کردن
	        </button>
	      </div>
        </div>
	    </form>
      </div>
    );
  }
}

QuizForm = reduxForm({
  
  form: 'quizForm',
  validate
  
})(QuizForm);

const selector = formValueSelector('quizForm');

QuizForm = connect(
  state => {
    const questions = selector(state, 'questions');
    const questionType = questions && questions.map(question => question.questionType);

    return { questionType: questionType }
  }
)(QuizForm)


export default QuizForm;