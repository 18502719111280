import React, { useState } from 'react';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import Q1write from './q1write';

const Collapse_info = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const id = localStorage.getItem('examnumber')
  return (
    <div>
      <Button color="secondary" onClick={toggle} style={{ marginBottom: '2rem' }}>اطلاعات بیشتر</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
            <Q1write />
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
}

export default Collapse_info;
