import React,{useState,useEffect} from 'react';
import Paginator from 'react-hooks-paginator';
import List from './list1';
import moment from 'jalali-moment';


function Pagation_result(){

  const [loding,setloding] = useState(false);
  const [totalRecords,settotalRecords] = useState(0);
  const [ajson,setajson] = useState()
 
  const pageLimit = 6;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
 
  useEffect(() => {

    //console.log("currentPage: "+currentPage)
    const email = localStorage.getItem('email');
    const api_token = localStorage.getItem('api_token');
    const datasend = {'email':email,'api_token':api_token};
    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json','Accept':'application/json' },
      body: JSON.stringify(datasend)
  };
  //console.log(requestOptions);
  fetch('https://api.quiz360.ir/public/api/v1/qustioninfo?page='+currentPage, requestOptions)
      .then(response => response.json())
      .then(data1 =>setdataapi(data1) );
      ////console.log(resp?.data.api_token)

      const setdataapi = data1 => {
        //console.log(data1)
        setloding(true)
        settotalRecords(data1?.meta?.to)
        
        setData(data1.data)
          
    if(data1?.data?.answernumber){
      
      
    }

    
  
  }
    
  }, []);
 
  useEffect(() => {
    setCurrentData(data?.slice(offset, offset + pageLimit));
  }, [offset, data]);



  //console.log("ajson: "+ajson);
  
//JSON.parse(item.ajson).map((item1,index1)=>{return item1.numberOfQuestions;}))}  key={index} />;}
//totalPoints  correctPoints private
  return (
    <div>
      {!loding ?(
      <div class="d-flex justify-content-center">
  <div class="spinner-border text-success" style={{width: '10rem', height: '10rem'}} role="status">
    <span class="sr-only">درحال بارگیری</span>
  </div>
</div>
):(<div>
    <div className="row" style={{textAlign:"center",display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        
    {currentData?.map((item,index)=>{return <List  
    title={item.qname}
    id1={item.examnumber}
    id1copy={item.examnumber}
    id2={"استفاده "+item.use}
    id3={moment(item.created_at, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}
    id5={true ? (<div class="btn-info" role="alert">مشاهده  نتایج</div>):('')}
    id5link={"/viewanswerinfo/"+item.examnumber}
    id4={item.public ? (<div className="btn-outline-secondary">خصوصی سازی</div>):(<div className="btn-outline-success">عمومی سازی</div>)}
    id4link={item.public ? ('/privatequstion/'+item.examnumber):('/publicqustion/'+item.examnumber)}
    id6={true ? (<div class="btn-outline-warning">ویرایش سوالات</div>):('')}
    id6link={"/editqustion/"+item.examnumber}
      key={item.id} />;})}
        


        </div>
        <div>
      <Paginator
        totalRecords={totalRecords}
        pageLimit={6}
        pageNeighbours={1}
        setOffset={setOffset}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageNextText="بعدی>>"
        pagePrevText="<<قبلی"
      />
    </div>
    </div>)}
    </div>
    );
}

export default Pagation_result;