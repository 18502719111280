import React from 'react'
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import {faGem} from '@fortawesome/free-solid-svg-icons';
import {faBookReader} from '@fortawesome/free-solid-svg-icons';
import {faHourglassStart} from '@fortawesome/free-solid-svg-icons';
import {faBookMedical} from '@fortawesome/free-solid-svg-icons';
import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import {faBorderAll} from '@fortawesome/free-solid-svg-icons';
import {faBoxes} from '@fortawesome/free-solid-svg-icons';
import {faBrush} from '@fortawesome/free-solid-svg-icons';
import {faAddressCard} from '@fortawesome/free-solid-svg-icons';
import {faAt} from '@fortawesome/free-solid-svg-icons';
import {faHome} from '@fortawesome/free-solid-svg-icons';


function Profilebutton(){


    const mystyle = {
        'width': '100px',
        'height': '100px',
        'font-size': '10px',
    }

    

    return(
        <div style={{mystyle}}>
            <div class="container text-xs-center">
                
    <div class="row">
        <div class="col-lg-12">
            <h3>امکانات کاربری عمومی</h3>
            <p>
                <Link to="/"  class="btn btn-outline-secondary">
                    <FontAwesomeIcon icon={faHome} />
                    <br />
                     صفحه اصلی
                    <br />
                    و اطلاعیه
                </Link>
                <Link to="/contactus"  class="btn btn-squared-default btn-primary">
                   <FontAwesomeIcon icon={faAddressCard} />
                    <br />
                    تماس با ما
                    <br />
                    و گزارش خطاها
                </Link>
                <Link to="/qnswerinfo" class="btn btn-squared-default btn-success">
                    <FontAwesomeIcon icon={faGem} />
                    
                    <br />
                    شرکت در آزمون
                    <br />
                    با شماره سوال
                </Link>
                <Link to="/viewanserresult"  class="btn btn-squared-default btn-warning">
                    <FontAwesomeIcon icon={faBookReader} />
                    <br />
                    مشاهده آزمون های
                    <br />
                    من
                </Link>
                <Link to="/endhalfquiz"  class="btn btn-squared-default btn-danger">
                    <FontAwesomeIcon icon={faHourglassStart} />
                    <br />
                    اتمام آزمون
                    <br />
                    نیمه کاره
                </Link>
                <Link to="/edituserinfo"  class="btn btn-squared-default btn-info">
                    <FontAwesomeIcon icon={faBookMedical} />
                    <br />
                    ویرایش
                    <br />
                    مشخصات کاربری
                </Link>
                <Link to="/publicpage"  class="btn btn-squared-default btn-primary">
                   <FontAwesomeIcon icon={faBookOpen} />
                    <br />
                       استفاده سوالات
                    <br />
                    عمومی شده
                </Link>
                <Link to="/answerresultwithansn" class="btn btn-squared-default btn-success">
                    <FontAwesomeIcon icon={faAt} />
                    
                    <br />
                      مشاهده نتیجه آزمون
                    <br />
                      با شماره آزمون
                </Link>
                
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-12">
            <h3>امکانات طراح سوال</h3>
            <p>
                <Link to="/viewanswerinfowithexn"  class="btn btn-squared-default-plain btn-primary">
                <FontAwesomeIcon icon={faBookmark} />
                    <br />
                    مشاهده نتایج
                    <br />
                    آزمون با شماره سوال
                </Link>
                <Link to="/viewqustion" class="btn btn-squared-default-plain btn-success">
                <FontAwesomeIcon icon={faBorderAll} />
                    <br />
                    مشاهده سوالات
                    <br />
                     طرح شده توسط من
                </Link>
                <Link to="/qmakeprofile" class="btn btn-squared-default-plain btn-info">
                <FontAwesomeIcon icon={faBoxes} />
                    <br />
                    طراحی سوال 
                    <br />
                    جدید
                </Link>

            </p>
        </div>
    </div>
</div>



        </div>
    );
}

export default Profilebutton;